import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import AppHeader from './AppHeader';

const HeaderWrapper = () => {
  const {
    state: { isSponsor },
  } = useContext(AppContext);
  return (
    <div>
      <Routes>
        <Route path={`${ROUTES?.NOTIFICATION}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.USERS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.MANUFACTURERS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.CUSTOMERS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.IMPORTS}/*`} element={<AppHeader />} />
        <Route
          path={`${ROUTES?.PRODUCT_CATEGORIES}/*`}
          element={<AppHeader />}
        />
        <Route path={`${ROUTES?.ROLES}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.RETAILERS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.REGIONS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.PRODUCTS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.PACKAGES}/*`} element={<AppHeader />} />
        <Route
          path={`${ROUTES?.PRICING_MODIFIERS}/*`}
          element={<AppHeader />}
        />
        <Route path={`${ROUTES?.QUESTIONNAIRES}/*`} element={<AppHeader />} />
        {isSponsor ? (
          <Route
            path={`${ROUTES?.SPONSOR_SETTING}/*`}
            element={<AppHeader />}
          />
        ) : (
          <Route path={`${ROUTES?.TENANT_SETTING}/*`} element={<AppHeader />} />
        )}
        <Route
          path={`${ROUTES?.BUSINESS_INFORMATION}/*`}
          element={<AppHeader />}
        />
        <Route
          path={`${ROUTES?.HOURS_OF_OPERATION}/*`}
          element={<AppHeader />}
        />
        <Route
          path={`${ROUTES?.MY_ACCOUNT_SETTING}/*`}
          element={<AppHeader />}
        />
        <Route path={`${ROUTES?.PLAN_SETTING}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.CONTACTS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.CUSTOMERS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.PROPERTIES}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.LEADS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.QUOTES}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.CAMPAIGNS}/*`} element={<AppHeader />} />
        <Route
          path={`${ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION}/*`}
          element={<AppHeader />}
        />
        <Route path={`${ROUTES?.DASHBOARD}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.API_SETTING}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.PERMISSIONS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.PRODUCT_PRICING}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.DYNAMIC_FIELDS}/*`} element={<AppHeader />} />
        <Route path={`${ROUTES?.INDUSTRIES}/*`} element={<AppHeader />} />
        <Route
          path={`${ROUTES?.IMPORT_EXPORT_QUEUE}/*`}
          element={<AppHeader />}
        />
        <Route path={`${ROUTES?.REST_API_LOGS}/*`} element={<AppHeader />} />
        <Route
          path={`${ROUTES?.NOTIFICATION_LOGS}/*`}
          element={<AppHeader />}
        />
        <Route path={`${ROUTES?.ACTIVITY_LOGS}/*`} element={<AppHeader />} />
        {isSponsor && (
          <>
            <Route
              path={`${ROUTES?.ASSIGNMENT_JOBS}/*`}
              element={<AppHeader />}
            />
            <Route path={`${ROUTES?.PROSPECTS}/*`} element={<AppHeader />} />
            <Route
              path={`${ROUTES?.SPONSOR_TENANTS}/*`}
              element={<AppHeader />}
            />
          </>
        )}
      </Routes>
    </div>
  );
};

export default HeaderWrapper;
