import {
  every,
  flatMap,
  forEach,
  isEmpty,
  isNaN,
  map,
  startCase,
  unionBy,
} from 'lodash';
import moment from 'moment';
import client from '../apollo';
import { messageContext } from '../app/components/AppContextHolder';
import {
  GET_PUBLIC_SIGN_URL,
  GET_SIGN_URL,
  GET_SIGN_URLS,
} from '../components/graphql/Mutation';
import { GET_ACCOUNT_STEP } from '../components/graphql/Query';
import history from '../historyData';
import api from './api';
import {
  ALLOW_IMAGE_TYPES,
  CONDITIONS,
  CONDITIONS_KEYS,
  CPQ_URL,
  DEFAULT_DATE_FORMAT,
  ENTITY_FIELDS_DATA_KEYS,
  ENTITY_WRAPPER,
  NUMERIC_CONDITIONS,
  QUOTE_MODIFIER_NUMERIC_CONDITIONS,
  READ,
  REGEX,
  ROUTES,
  SIGNUP_AUTH,
  WRITE,
} from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);
// Portal related methods end

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const refreshGrid = () => {
  // eslint-disable-next-line no-undef
  window?.dispatchEvent(new Event('refresh-grid'));
};

export const formatDate = (
  datetime,
  format = `${DEFAULT_DATE_FORMAT} hh:mm A`,
) => {
  if (datetime && moment && format) {
    return moment(datetime)?.format(format);
  }

  return datetime;
};

export const dateFormatWithoutTime = (dateFormat) => {
  const checkForCapitalFormat = dateFormat?.search(/H/);
  const checkForSmallFormat = dateFormat?.search(/h/);
  if (checkForCapitalFormat > 0) {
    return dateFormat?.substring(0, checkForCapitalFormat);
  }
  if (checkForSmallFormat > 0) {
    return dateFormat?.substring(0, checkForSmallFormat);
  }
  return dateFormat;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
    whitespace: true,
  },
  requiredWithoutMessage: {
    required: true,
    message: '',
    whitespace: true,
  },
  requiredWhiteSpaceAllowed: {
    required: true,
    message: 'Required',
  },
  slug: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.SUB_DOMAIN?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please Enter a valid slug name!');
      }
      return Promise?.resolve();
    },
  }),
  subDomain: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.SUB_DOMAIN?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please Enter a valid domain name!');
      }
      return Promise?.resolve();
    },
  }),
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
  zipCode: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (value?.length < 3 || value?.length > 5 || parseInt(value, 10) === 0) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid ZIP Code');
      }
      return Promise?.resolve();
    },
  }),
  url: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.WEB_URL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid URL');
      }
      return Promise?.resolve();
    },
  }),
  color: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.COLOR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Color Code');
      }
      return Promise?.resolve();
    },
  }),
  startDate: (endDate) => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (endDate && value > endDate) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Start date cannot be greater than end date!');
      }
      return Promise?.resolve();
    },
  }),
  endDate: (startDate) => ({
    validator(rule, value) {
      if (!value || !startDate) {
        return Promise?.resolve();
      }
      if (startDate && value < startDate) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Expiry date cannot be less than start date!');
      }
      return Promise?.resolve();
    },
  }),
  firstName: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          'First Name should not contain the special characters.',
        );
      }
      return Promise?.resolve();
    },
  }),
  lastName: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          'Last Name should not contain the special characters.',
        );
      }
      return Promise?.resolve();
    },
  }),
  address: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.ADDRESS?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          'Address should not contain the special characters.',
        );
      }
      return Promise?.resolve();
    },
  }),
  dateFormat: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      const currentDate = moment(Date?.now())?.format(value);
      const isValidDateFormat = moment(currentDate, value, true)?.isValid();
      const isDate = new Date(currentDate);

      if (
        value?.charAt(0) === 'L' ||
        value?.charAt(0) === 'l' ||
        value?.charAt(1) === 'L' ||
        value?.charAt(1) === 'l'
      ) {
        if (!REGEX?.DATE_FORMAT?.test(value)) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise?.reject('Invalid Date Format.');
        }
      }
      if (isNaN(isDate?.getMonth())) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Invalid Date Format.');
      }
      if (!isValidDateFormat) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Invalid Date Format.');
      }
      return Promise?.resolve();
    },
  }),
  dynamicFieldsUpload: ({
    fieldRequired = false,
    fileValue = [],
    maxFile = 10,
    size = 50,
    limitFileSize = false,
  }) => ({
    type: 'array',
    required: fieldRequired,
    validator(rule, value) {
      const isSameType = every(value, (item) => {
        const { name = '' } = item;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const newValue = fileValue?.includes('JPEG')
          ? [...fileValue, 'JPG']
          : fileValue;
        return newValue?.includes(ext?.toUpperCase());
      });
      if (!isSameType) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          `File(s) must be of type ${fileValue?.join(', ')?.toLowerCase()}`,
        );
      }
      if (value?.length > maxFile) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(`Max ${maxFile} File(s) are allowed!`);
      }
      let totalSize = 0;
      forEach(value, (item) => {
        totalSize += item?.size;
      });
      const isLt2M = totalSize / 1024 / 1024 < (limitFileSize ? size : 50);
      if (!isLt2M) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(`File(s) must be smaller than ${size} MB!`);
      }
      if (fieldRequired && !value?.length) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please Upload File(s)');
      }
      return Promise?.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  if (cleaned?.length > 10) {
    const match = cleaned?.match(/^(\d)(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }
  } else {
    // Check if the input is of correct length
    const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const minutesOfDay = (date) => date?.minutes() + date?.hours() * 60;

export const formatUnits = (unit) => {
  const formatedUnit = unit || 0;

  return Number(formatedUnit)?.toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formItemProps = { normalize: (value) => value.trim() };

export const handleCsvDownload = (data, name = 'data.csv') => {
  // eslint-disable-next-line no-undef
  const a = document?.createElement('a');
  a.href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(data)}`;
  a.target = '_Blank';
  a.download = name;
  // eslint-disable-next-line no-undef
  document?.body?.appendChild(a);
  a?.click();
  // eslint-disable-next-line no-undef
  document?.body?.removeChild(a);
};

export const downloadFromURL = async (uri, name) => {
  const fileName = uri?.match(/[^/?#]+(?=$|[?#])/)?.[0];
  const response = await fetch(uri);
  const blob = await response?.blob();
  // eslint-disable-next-line no-undef
  const url = window?.URL?.createObjectURL(blob);
  // eslint-disable-next-line no-undef
  const link = document?.createElement('a');
  link.href = url;
  link.download = name || fileName; // Set the desired filename
  // eslint-disable-next-line no-undef
  document?.body?.appendChild(link);
  link?.click();
  // eslint-disable-next-line no-undef
  document?.body?.removeChild(link);
};

const checkSignUpFlag = () => {
  // eslint-disable-next-line no-undef
  const signupFlag = localStorage?.getItem(SIGNUP_AUTH) || false;
  if (!signupFlag) {
    // eslint-disable-next-line no-undef
    localStorage?.setItem(SIGNUP_AUTH, true);
  }
};

export const fetchStep = async ({
  dispatch,
  setLoading = false,
  changeRoute = true,
  initializeAuth,
  getToken,
  queryToken,
}) => {
  try {
    if (setLoading) {
      dispatch({
        type: 'SET_LOADING',
        data: true,
      });
    }
    // eslint-disable-next-line no-undef
    if (queryToken && window?.location?.pathname?.includes(ROUTES?.LOGIN)) {
      await dispatch({ type: 'LOGOUT' });
      await dispatch({
        type: 'SET_LOADING',
        data: false,
      });
      // eslint-disable-next-line no-undef
      history?.replace(window?.location);
      return;
    }
    const data = await client?.query({
      query: GET_ACCOUNT_STEP,
      fetchPolicy: 'network-only',
    });

    if (data) {
      if (initializeAuth && getToken) {
        initializeAuth(getToken(), data?.data?.getUserNextStep?.user);
      }
      if (dispatch) {
        dispatch({
          type: 'SET_ONBOARDING_DATA',
          data: data?.data?.getUserNextStep,
        });
        dispatch({
          type: 'SET_GLOBAL_DATE_FORMAT',
          data: data?.data?.getUserNextStep?.data?.timeFormat,
        });
        dispatch({
          type: 'SET_PERMISSIONS',
          data: flatMap(
            data?.data?.getUserNextStep?.allowedPermission,
            'permissions',
          ),
        });
      }
      if (changeRoute) {
        const nextStep = data?.data?.getUserNextStep?.nextStep;
        switch (nextStep) {
          case 'ACCOUNT_SIGNUP1':
            history?.push(`${ROUTES?.SIGNUP}/administrator`);
            break;
          case 'ACCOUNT_SIGNUP2':
            checkSignUpFlag();
            history?.push(`${ROUTES?.SIGNUP}/business-info`);
            break;
          case 'ACCOUNT_SIGNUP3':
            checkSignUpFlag();
            history?.push(`${ROUTES?.SIGNUP}/plan`);
            break;
          case 'ACCOUNT_SIGNUP4':
            checkSignUpFlag();
            history?.push(`${ROUTES?.SIGNUP}/success`);
            break;
          default:
            // commented this code as we have added alert in layout.
            // if (getCurrentUser) {
            //   // eslint-disable-next-line no-case-declarations
            //   const userData = getCurrentUser();
            //   if (!userData?.emailVerified) {
            //     message?.destroy();
            //     message?.warn('Please verify your e-mail');
            //   }
            // }

            if (
              // eslint-disable-next-line no-undef
              window?.location?.pathname?.includes(ROUTES?.SIGNUP) ||
              // eslint-disable-next-line no-undef
              window?.location?.pathname?.includes(ROUTES?.ONBOARDING) ||
              // eslint-disable-next-line no-undef
              window?.location?.pathname?.includes(ROUTES?.LOGIN)
            ) {
              history?.push(ROUTES?.MAIN);
            }
            break;
        }
      }
    }
    if (setLoading) {
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
    }
  } catch (err) {
    if (setLoading) {
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
    }
    return err;
  }
};

export const toDataURL = (url) =>
  // eslint-disable-next-line no-undef
  fetch(url)
    .then((response) => response?.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          // eslint-disable-next-line no-undef
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader?.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export const displayZipCodes = (value) => {
  if (value?.length === 3) {
    return `00${value}`;
  }
  if (value?.length === 4) {
    return `0${value}`;
  }
  return value;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const copyToClipboard = (str) => {
  // eslint-disable-next-line no-undef
  const el = document?.createElement('textarea');
  el.value = str;
  // eslint-disable-next-line no-undef
  document?.body?.appendChild(el);
  el?.select();
  // eslint-disable-next-line no-undef
  document?.execCommand('copy');
  // eslint-disable-next-line no-undef
  document?.body?.removeChild(el);
};

export const generateEmbeddedUrl = (url) => {
  if (url) {
    if (url?.includes('embed')) {
      const res = url?.split('/');
      const autoPlaEmbedUrl = `${url}?playlist=${res?.[4]}&loop=1&controls=0`;
      return autoPlaEmbedUrl;
    }
    if (url?.includes('=')) {
      const res = url?.split('=');
      const embeddedUrl = `https://www.youtube.com/embed/${res?.[1]}?playlist=${res?.[1]}&loop=1&controls=0`;

      return embeddedUrl;
    }
    const res = url?.split('/');
    const embeddedUrl = `https://www.youtube.com/embed/${res?.[3]}?playlist=${res?.[3]}&loop=1&controls=0`;
    return embeddedUrl;
  }
  return 'https://www.youtube.com';
};

export const cpqRetailUrl = (subDomain = '', isSponsor = false) => {
  const env = process.env.REACT_APP_ENV;
  const cleanEnv = env?.replace(/^production-|^production/, '');

  return isSponsor
    ? `sponsor-${subDomain}.${cleanEnv}${
        cleanEnv?.length > 0 ? '.' : ''
      }${CPQ_URL}`
    : `${subDomain}.${cleanEnv}${cleanEnv?.length > 0 ? '.' : ''}${CPQ_URL}`;
};

export const sampleImportUrl = (moduleName = '') =>
  `https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}/assets/imports/${moduleName}-sample.csv`;

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    color += letters?.[Math?.floor(Math?.random() * 16)];
  }
  return color;
};

export const removeDuplicateData = (arrayList = [], keys = []) =>
  arrayList?.filter(
    ((s) => (o) =>
      ((k) => !s?.has(k) && s?.add(k))(keys?.map((k) => o?.[k])?.join('|')))(
      new Set(),
    ),
  );

export const checkPermissions = (
  userPermissions = [],
  allowedPermissions = [],
) => {
  if (allowedPermissions?.length === 0) {
    return true;
  }

  return userPermissions?.some((permission) =>
    allowedPermissions?.includes(permission),
  );
};

// get and upload logo functions

export const getLogo = async ({
  logoObject,
  setDataState,
  setLoadingState,
  isBase64 = false,
  dispatch,
}) => {
  const { extension = '', contentType = '', key = '' } = logoObject;
  try {
    const res = await client?.mutate({
      mutation: GET_SIGN_URL,
      variables: {
        action: READ,
        extension,
        contentType,
        key,
      },
    });
    if (res?.data?.generateSignedUrl) {
      const { signedRequest, url } = res?.data?.generateSignedUrl;
      if (isBase64 && dispatch) {
        toDataURL(signedRequest).then((dataUrl) => {
          dispatch({ type: 'SET_LOGO_URL', data: dataUrl });
        });
        return;
      }
      if (setDataState && setLoadingState) {
        setDataState({ url: signedRequest, title: url?.split('_')?.[1] });
        setLoadingState(false);
      }
    }
  } catch {
    if (setLoadingState) {
      setLoadingState(false);
    }
  }
};

export const uploadFile = async ({
  logoObject = {},
  currentUser,
  uuid,
  folder = 'drf',
  fieldKey = 'logo',
  pathUpload = '',
}) => {
  if (logoObject && !logoObject?.url) {
    const { name, type } = logoObject;
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    const timestamp = Date?.now();
    const filename = name?.split('.')?.slice(0, -1)?.join('.');
    const newFilename = `${timestamp}_${filename}.${ext}`;
    const key = pathUpload
      ? `${pathUpload}/${newFilename}`
      : `organization/${
          currentUser?.tenantId || currentUser?.sponsorId
        }/${folder}/${uuid}/${fieldKey}/${newFilename}`;
    const res = await client?.mutate({
      mutation: GET_SIGN_URL,
      variables: {
        action: WRITE,
        extension: `.${ext}`,
        contentType: type,
        key,
      },
    });
    if (res?.data?.generateSignedUrl) {
      const { signedRequest, url: URL } = res?.data?.generateSignedUrl;
      // above url is send to the backend
      try {
        const response = await api(signedRequest, {
          method: 'PUT',
          data: logoObject?.originFileObj || logoObject,
          headers: {
            'Content-Type': type,
          },
        });
        if (response) {
          const logo = {
            url: URL,
            name,
            key,
            extension: ext,
            contentType: type,
          };
          return logo;
        }
      } catch (error) {
        messageContext?.error('got some problem');
      }
    }
  }
};

export const uploadMultipleFiles = async ({
  files = [],
  currentUser,
  uuid,
  folder = 'drf',
  fieldKey = 'logo',
}) => {
  if (files?.length > 0) {
    try {
      const signVariables = await Promise?.all(
        map(files, async (item) => {
          const { name = '', type = '' } = item;
          const ext = name?.substring(name?.lastIndexOf('.') + 1);
          const timestamp = Date?.now();
          const filename = name?.split('.')?.slice(0, -1)?.join('.');
          const newFilename = `${timestamp}_${filename}.${ext}`;
          const key = `organization/${
            currentUser?.tenantId || currentUser?.sponsorId
          }/${folder}/${uuid}/${fieldKey}/${newFilename}`;
          return {
            extension: `.${ext}`,
            contentType: type,
            key,
          };
        }),
      );
      if (signVariables) {
        const data = [...signVariables];
        const res = await client?.mutate({
          mutation: GET_SIGN_URLS,
          variables: {
            action: WRITE,
            data,
          },
        });

        if (res?.data?.generateSignedUrls) {
          try {
            const finalResponse = await Promise?.all(
              map(res?.data?.generateSignedUrls, async (signUrl, index) => {
                const {
                  key,
                  extension = '',
                  contentType = '',
                } = signVariables?.[index];
                const {
                  name = '',
                  size = null,
                  originFileObj = null,
                } = files?.[index];

                const response = await api(signUrl?.signedRequest, {
                  method: 'PUT',
                  data: originFileObj,
                  headers: {
                    'Content-Type': contentType,
                  },
                });
                if (response) {
                  const logo = {
                    url: signUrl?.url,
                    name,
                    key,
                    extension,
                    contentType,
                    size: size / 1024 / 1024,
                  };
                  return logo;
                }
              }),
            );
            return finalResponse;
          } catch (error) {
            messageContext?.error(error?.message);
          }
        }
      }
    } catch (error) {
      messageContext?.error(error?.message);
    }
  }
};

export const getPublicUrl = async ({ logoObject }) => {
  const initialArray = logoObject?.fileList || logoObject;
  if (!isEmpty(initialArray)) {
    const imageArray = initialArray?.map((item) => ({
      extension: item?.extension,
      contentType: item?.contentType,
      key: item?.key,
    }));

    try {
      if (imageArray?.length > 0) {
        const res = await client?.mutate({
          mutation: GET_PUBLIC_SIGN_URL,
          variables: {
            action: READ,
            data: imageArray,
          },
        });

        if (res?.data?.generatePublicSignedUrl) {
          return initialArray?.map((item) => ({
            ...item,
            url: res?.data?.generatePublicSignedUrl?.find(
              (items) => items?.key === item?.key,
            )?.signedRequest,
          }));
        }
      }
    } catch (error) {
      messageContext?.error(error?.message);
    }
  }
};

export const objectWithoutKey = (object, key) => {
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

export const strippedString = (string) => string?.replace(/(<([^>]+)>)/gi, '');

export const uploadImageNormalize = ({ file, fileList: files }) => {
  if (file?.status === 'removed') {
    return;
  }
  const imageFiles = [];
  forEach(files, (item) => {
    const ext = item?.name?.substring(item?.name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext)) {
      imageFiles?.push(item);
    } else {
      messageContext?.error(`${item?.name} file is not image file.`);
    }
  });
  return imageFiles;
};
export const handleExportCommon = async ({
  filters = null,
  module = 'USER',
  key = 'ALL',
  selectedKeys = [],
}) => {
  if (key === 'SELECTED' && !selectedKeys?.length) {
    messageContext?.destroy();
    messageContext?.error('Please select records!');
    return;
  }
  try {
    await api({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module,
        filtersObj: {
          ...filters,
          ...(key === 'SELECTED' && { id: selectedKeys }),
        },
      },
    });
    messageContext?.info(
      'Your data export job has begun. We will notify you via email as soon as it is completed. ',
      5,
    );
  } catch (error) {
    messageContext?.error(error?.response?.data?.error || 'got some problem');
  }
};

export const checkForSpecialCharsInPassword = (pwd = []) => {
  const ALLOWED_SPECIAL_CHARS = ['!', '@', '#', '$', '%', '^', '&', '*'];
  let checkFlag = false;
  pwd?.map((item) => {
    if (checkFlag) {
      return;
    }
    if (ALLOWED_SPECIAL_CHARS?.includes(item)) {
      checkFlag = true;
    }
    return false;
  });

  return checkFlag;
};

export const createUnitInputParameterMessage = (unitInputParameter) => {
  const conditionValue = QUOTE_MODIFIER_NUMERIC_CONDITIONS?.find(
    (condObj) => condObj?.value === unitInputParameter?.condition,
  );

  let conditionMessage = null;
  if (
    conditionValue?.value === CONDITIONS_KEYS?.EQUAL ||
    conditionValue?.value === CONDITIONS_KEYS?.NOT_EQUAL
  ) {
    const convertedValue = unitInputParameter?.value?.toLocaleString('en', {
      useGrouping: false,
      minimumFractionDigits: 2,
    });
    conditionMessage = `${conditionValue?.label} ${convertedValue}`;
  }
  if (conditionValue?.value === CONDITIONS_KEYS?.GREATER_LESS_RANGE) {
    const convertedGreaterThanValue = unitInputParameter?.greaterThanValue?.toLocaleString(
      'en',
      { useGrouping: false, minimumFractionDigits: 2 },
    );
    const convertedLessThanValue = unitInputParameter?.lessThanValue?.toLocaleString(
      'en',
      { useGrouping: false, minimumFractionDigits: 2 },
    );
    if (
      unitInputParameter?.greaterThanCheck &&
      unitInputParameter?.lessThanCheck
    ) {
      conditionMessage = `in Between ${convertedGreaterThanValue} - ${convertedLessThanValue}`;
    }

    if (
      unitInputParameter?.greaterThanCheck &&
      !unitInputParameter?.lessThanCheck
    ) {
      conditionMessage = `is Greater Than ${convertedGreaterThanValue}`;
    }
    if (
      unitInputParameter?.lessThanCheck &&
      !unitInputParameter?.greaterThanCheck
    ) {
      conditionMessage = `is Less Than ${convertedLessThanValue}`;
    }
  }

  return `${unitInputParameter?.conversionParaMeterName}(${unitInputParameter?.unitOfMeasureTitle})  ${conditionMessage}`;
};

export const createEntityFilterMessage = (
  entityFilter,
  globalDateFormat = 'MM-DD-YY',
) => {
  const allConditions = unionBy(NUMERIC_CONDITIONS, CONDITIONS, 'value');

  const conditionValue = allConditions?.find(
    (condObj) => condObj?.value === entityFilter?.key,
  );

  let message = null;

  if (
    entityFilter?.key === CONDITIONS_KEYS?.EQUAL ||
    entityFilter?.key === CONDITIONS_KEYS?.NOT_EQUAL ||
    entityFilter?.key === CONDITIONS_KEYS?.EQUAL_TO ||
    entityFilter?.key === CONDITIONS_KEYS?.NOT_EQUAL_TO
  ) {
    if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
      message = `${conditionValue?.label} ${moment(
        entityFilter?.values,
      )?.format(globalDateFormat)}`;
    } else if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.PICK_LIST) {
      const labels = entityFilter?.values?.map(
        (item) => item?.name || item?.title || item?.label,
      );
      message = `${conditionValue?.label} ${labels?.join(', ')}`;
    } else if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.BOOLEAN) {
      message = `${conditionValue?.label} ${entityFilter?.values?.toString()}`;
    } else {
      message = `${conditionValue?.label} ${entityFilter?.values}`;
    }
  }
  if (
    entityFilter?.key === CONDITIONS_KEYS?.CONTAINS ||
    entityFilter?.key === CONDITIONS_KEYS?.NOT_CONTAINS
  ) {
    if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
      message = `${conditionValue?.label} ${moment(entityFilter?.values).format(
        globalDateFormat,
      )}`;
    } else if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.PICK_LIST) {
      const labels = entityFilter?.values?.map((item) => item?.name);
      message = `${conditionValue?.label} ${labels?.join(', ')}`;
    } else {
      message = `${conditionValue?.label} ${entityFilter?.values?.toString()}`;
    }
  }
  if (conditionValue?.value === CONDITIONS_KEYS?.GREATER_LESS_RANGE) {
    // need to check for data types
    let convertedGreaterThanValue;
    let convertedLessThanValue;
    if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
      convertedGreaterThanValue = moment(
        entityFilter?.values?.greaterThan?.max,
      )?.format(globalDateFormat);
      convertedLessThanValue = moment(
        entityFilter?.values?.lessThan?.min,
      )?.format(globalDateFormat);
    } else {
      convertedGreaterThanValue = entityFilter?.values?.greaterThan?.max?.toLocaleString(
        'en',
        { useGrouping: false, minimumFractionDigits: 2 },
      );
      convertedLessThanValue = entityFilter?.values?.lessThan?.min?.toLocaleString(
        'en',
        {
          useGrouping: false,
          minimumFractionDigits: 2,
        },
      );
    }

    if (
      entityFilter?.values?.greaterThan?.check &&
      entityFilter?.values?.lessThan?.check
    ) {
      message = `in Between ${convertedGreaterThanValue} - ${convertedLessThanValue}`;
    }

    if (
      entityFilter?.values?.greaterThan?.check &&
      !entityFilter?.values?.lessThan?.check
    ) {
      message = `is Greater Than ${convertedGreaterThanValue}`;
    }
    if (
      !entityFilter?.values?.greaterThan?.check &&
      entityFilter?.values?.lessThan?.check
    ) {
      message = `is Less Than ${convertedLessThanValue}`;
    }
  }
  return message;
};
export const fieldLevelDisabled = ({
  isSponsorRecord = false,
  fieldName = '',
  editableFields = [],
}) =>
  process.env.REACT_APP_PORTAL !== 'SPONSOR' &&
  isSponsorRecord &&
  !editableFields?.includes(fieldName);
// static plan details

export const planDetails = (sponsorCreatedTenant, businessName) => {
  if (sponsorCreatedTenant && businessName) {
    return {
      title: `${businessName}`,
      description: 'Get seamless updates from your sponsor.',
      firstBullet: 'Customize, tailor, and update pricing for your customers.',
      secondBullet: 'Leverage sponsor relationships to maximize your business.',
      thirdBullet: 'Powerful real-time results dashboard.',
    };
  }
  return {
    title: 'Free Trial',
    description:
      'Make more money in less time using configurable price quoting tools.',
    firstBullet: 'All-in-one pricing engine.',
    secondBullet: 'More sales at less cost improve your margins.',
    thirdBullet: 'Powerful real-time result dashboard.',
  };
};

export const getEntityFieldName = (entityName) => {
  const entity = Object?.keys(ENTITY_WRAPPER)?.find(
    (item) => item === entityName,
  );
  return entity ? ENTITY_WRAPPER?.[entity] : startCase(entityName);
};
