import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Row,
  Tag,
} from 'antd';
import { debounce, filter, forEach, isEmpty, kebabCase, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { messageContext } from '../../../app/components/AppContextHolder';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import QuestionnaireIconComponent from '../../../app/components/iconComponents/QuestionnaireIconComponent';
import SecondaryQuestionnaireIcon from '../../../app/components/iconComponents/SecondaryQuestionaireIcon';
import FilterIcon from '../../../assets/filter.svg';
import {
  QUESTIONNAIRE_TYPE,
  ROUTES,
  SKIP_RECORD,
} from '../../../common/constants';
import { checkPermissions } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import {
  COPY_QUESTIONNAIRE,
  DELETE_QUESTIONNAIRES,
  UPDATE_QUESTIONNAIRES,
} from '../graphql/Mutations';
import {
  BRAND_FILTER,
  GET_QUESTIONNAIRES,
  GET_SPONSOR_FILTER,
  QUESTIONNAIRE_FILTER,
} from '../graphql/Queries';
import SecondarySettingModal from './SecondarySettingModal';
import SettingModal from './SettingModal';

let scrollDebounce = null;

const SecondaryQuestionnaireTable = () => {
  const {
    state: { pageSize, permissions, isSponsor },
    dispatch,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const initialQuestionnaireFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [questionnaireFilter, setQuestionnaireFilter] = useState(
    initialQuestionnaireFilter,
  );
  const [filters, setFilters] = useState();
  const [filtersCopyState, setFiltersCopyState] = useState();
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [searchValue, setSearchValue] = useState(null);

  const [
    updateQuestionnaireMutate,
    { loading: updateQuestionnaireLoading },
  ] = useMutation(UPDATE_QUESTIONNAIRES, {
    onError() {},
  });

  const [
    deleteQuestionnaire,
    { loading: deleteQuestionnaireLoading },
  ] = useMutation(DELETE_QUESTIONNAIRES, {
    onError() {},
  });

  const [
    copyQuestionnaire,
    { loading: copyQuestionnaireLoading },
  ] = useMutation(COPY_QUESTIONNAIRE, {
    onError() {},
  });

  const [fetchQuestionnaireData, { loading, data }] = useLazyQuery(
    GET_QUESTIONNAIRES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.questionnaires?.count,
        };
        setPaginationProp(pagination);
        setDataLoading(false);
      },
      onError() {},
    },
  );

  const [questionnaireFilters] = useLazyQuery(QUESTIONNAIRE_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const optionsCopy = scrollFlag ? [...filterList] : [];
      forEach(res?.questionnaireFilters?.data, (item) =>
        optionsCopy?.push(item?.[filterIndex]),
      );
      setFilterList(optionsCopy);
      setScrollFlag(false);
      setFilterIsEnd(res?.questionnaireFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [brandFilters] = useLazyQuery(BRAND_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push(item?.name),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push(item?.name),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.questionnaireFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [sponsorFilter] = useLazyQuery(GET_SPONSOR_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.sponsorFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });
  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_QUESTIONNAIRE_LIST']);
    const whereFilter = location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }
    if (permitted) {
      setDataLoading(true);
      fetchQuestionnaireData({
        variables: {
          filter: parsedObject?.questionnaireFilter ?? questionnaireFilter,
          where: {
            type: [QUESTIONNAIRE_TYPE?.ROQ_PATH],
            ...(parsedObject && { ...parsedObject?.filters }),
          },
        },
      });
      if (!isEmpty(parsedObject)) {
        setFilters({ ...parsedObject?.filters });
        setFiltersCopyState({
          ...parsedObject?.filters,
        });
        setSearchValue(parsedObject?.questionnaireFilter?.search);
        const sorter = {
          order:
            parsedObject?.questionnaireFilter?.sortBy === 'ASC'
              ? 'ascend'
              : 'descend',
          columnKey: parsedObject?.questionnaireFilter?.sortOn,
        };
        setSortedInfo({ ...sorter });
        setQuestionnaireFilter({ ...parsedObject?.questionnaireFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm, dataIndex) => {
    if (dataIndex === 'closeRate') {
      if (
        !filtersCopyState?.closeRate?.min?.toString() ||
        !filtersCopyState?.closeRate?.max?.toString()
      ) {
        messageContext?.destroy();
        messageContext?.error('Please enter values');
        return;
      }
      if (filtersCopyState?.[dataIndex]?.min < 0) {
        return messageContext?.error('Min value can not be less than zero');
      }
      if (filtersCopyState?.[dataIndex]?.max < 0) {
        return messageContext?.error('Max value can not be less than zero');
      }
      if (
        filtersCopyState?.[dataIndex]?.min >= filtersCopyState?.[dataIndex]?.max
      ) {
        return messageContext?.error('Max value can`t be less than min value');
      }
    }
    setDataLoading(true);
    fetchQuestionnaireData({
      variables: {
        filter: { ...questionnaireFilter, skip: 0 },
        where: {
          type: [QUESTIONNAIRE_TYPE?.ROQ_PATH],
          ...(filtersCopyState && { ...filtersCopyState }),
        },
      },
    });
    setFilters(filtersCopyState);
    setQuestionnaireFilter({
      ...questionnaireFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'brandName':
          brandFilters({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'name',
              },
            },
          });
          break;

        case 'sponsorName':
          sponsorFilter({
            variables: {
              filter: {
                sortOn: 'businessName',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'businessName',
              },
            },
          });
          break;

        default:
          questionnaireFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'brandName':
            brandFilters({
              variables: {
                filter: {
                  sortOn: 'name',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'name',
                },
              },
            });
            break;

          case 'sponsorName':
            sponsorFilter({
              variables: {
                filter: {
                  sortOn: 'businessName',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'businessName',
                },
              },
            });
            break;

          default:
            questionnaireFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    let filtersCopy = {
      ...filters,
    };
    if (dataIndex === 'closeRate') {
      delete filtersCopy?.closeRate;
    } else {
      filtersCopy = {
        ...filters,
        [dataIndex]: [],
      };
    }
    setFilters(filtersCopy);
    setDataLoading(true);
    fetchQuestionnaireData({
      variables: {
        filter: {
          ...questionnaireFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        where: {
          type: [QUESTIONNAIRE_TYPE?.ROQ_PATH],
          ...(filtersCopy && { ...filtersCopy }),
        },
      },
    });
    setQuestionnaireFilter({
      ...questionnaireFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditQuestionnaire = (record) => {
    const otherFilters = { questionnaireFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);

    if (record?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      navigate(
        `${ROUTES?.QUESTIONNAIRES_SECONDARY}/${record?.id}${ROUTES?.PAGE}/${
          !isEmpty(record?.pageKey) && record?.pageKey?.[0] !== null
            ? kebabCase(record?.pageKey?.[0])
            : 'create'
        }?sp=false`,
        {
          state: {
            stringifyObject,
          },
        },
      );
    }
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setQuestionnaireFilter({
        ...questionnaireFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      setDataLoading(true);
      fetchQuestionnaireData({
        variables: {
          filter: {
            ...questionnaireFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          where: {
            type: [QUESTIONNAIRE_TYPE?.ROQ_PATH],
            ...(filters && { ...filters }),
          },
        },
      });
    } else {
      setQuestionnaireFilter({
        ...questionnaireFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      setDataLoading(true);
      fetchQuestionnaireData({
        variables: {
          filter: {
            ...questionnaireFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          where: {
            type: [QUESTIONNAIRE_TYPE?.ROQ_PATH],
            ...(filters && { ...filters }),
          },
        },
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'sponsorName':
        sponsorFilter({
          variables: {
            filter: {
              sortOn: 'businessName',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'businessName',
            },
          },
        });
        break;

      case 'brandName':
        brandFilters({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'name',
            },
          },
        });
        break;

      default:
        questionnaireFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const handleChangeRate = (value, input, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: { ...filtersCopyState?.[dataIndex], [input]: value },
    };

    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'closeRate') {
        return (
          <div className="custom-filter-dropdown amount-filter-dropdown">
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.[dataIndex]
                      ? filtersCopyState?.[dataIndex]?.min
                      : false
                  }
                  placeholder="min"
                  onChange={(value) =>
                    handleChangeRate(value, 'min', dataIndex)
                  }
                  isPrice
                  prefix=""
                  suffix="%"
                  isAllowed={(inputObj) => {
                    const { value } = inputObj;
                    if (Number(value) < 0 || Number(value) > 100) {
                      return false;
                    }
                    return true;
                  }}
                />
              </Col>
              <Col span={12}>
                <PriceComponent
                  placeholder="max"
                  value={
                    filtersCopyState?.[dataIndex]
                      ? filtersCopyState?.[dataIndex]?.max
                      : false
                  }
                  onChange={(value) =>
                    handleChangeRate(value, 'max', dataIndex)
                  }
                  isPrice
                  prefix=""
                  suffix="%"
                  isAllowed={(inputObj) => {
                    const { value } = inputObj;
                    if (Number(value) < 0 || Number(value) > 100) {
                      return false;
                    }
                    return true;
                  }}
                />
              </Col>
            </Row>

            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'isActive' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item,
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const handleDeleteQuestionnaire = async (editQuestionnaireData) => {
    const response = await deleteQuestionnaire({
      variables: { where: { id: editQuestionnaireData?.id } },
    });
    if (response?.data?.deleteQuestionnaire) {
      setDataLoading(true);
      fetchQuestionnaireData({
        variables: {
          filter: questionnaireFilter,
          where: {
            type: [QUESTIONNAIRE_TYPE?.ROQ_PATH],
            ...(filters && { ...filters }),
          },
        },
      });
    }
  };

  const handleQuestionnaireStatus = async (editQuestionnaireData) => {
    const editData = {
      title: editQuestionnaireData?.title,
      link: editQuestionnaireData?.link,
      brandId: editQuestionnaireData?.brandId,
      uuid: editQuestionnaireData?.uuid,
      isScheduleEnabled: editQuestionnaireData?.isScheduleEnabled,
      slug: editQuestionnaireData?.slug,
      ...(editQuestionnaireData?.cutOffTime && {
        cutOffTime: {
          monday: {
            checked: editQuestionnaireData?.mondayCheckbox,
            time: moment(editQuestionnaireData?.monday)?.format('h:mm a'),
          },
          tuesday: {
            checked: editQuestionnaireData?.tuesdayCheckbox,
            time: moment(editQuestionnaireData?.tuesday)?.format('h:mm a'),
          },
          wednesday: {
            checked: editQuestionnaireData?.wednesdayCheckbox,
            time: moment(editQuestionnaireData?.wednesday)?.format('h:mm a'),
          },
          thursday: {
            checked: editQuestionnaireData?.thursdayCheckbox,
            time: moment(editQuestionnaireData?.thursday)?.format('h:mm a'),
          },
          friday: {
            checked: editQuestionnaireData?.fridayCheckbox,
            time: moment(editQuestionnaireData?.friday)?.format('h:mm a'),
          },
          saturday: {
            checked: editQuestionnaireData?.saturdayCheckbox,
            time: moment(editQuestionnaireData?.saturday)?.format('h:mm a'),
          },
          sunday: {
            checked: editQuestionnaireData?.sundayCheckbox,
            time: moment(editQuestionnaireData?.sunday)?.format('h:mm a'),
          },
        },
      }),
      isActive: !editQuestionnaireData?.isActive,
      type: QUESTIONNAIRE_TYPE?.ROQ_PATH,
      industryId: editQuestionnaireData?.industryId,
    };
    const response = await updateQuestionnaireMutate({
      variables: {
        data: { ...editData },
        where: { id: editQuestionnaireData?.id },
      },
    });
    if (response?.data?.updateQuestionnaire) {
      setDataLoading(true);
      fetchQuestionnaireData({
        variables: {
          filter: questionnaireFilter,
          where: {
            type: [QUESTIONNAIRE_TYPE?.ROQ_PATH],
            ...(filters && { ...filters }),
          },
        },
      });
    }
  };

  const handleDuplicateQuestionnaire = async (editQuestionnaireData) => {
    const response = await copyQuestionnaire({
      variables: { where: { id: editQuestionnaireData?.id } },
    });
    if (response?.data?.copyQuestionnaire) {
      setDataLoading(true);
      fetchQuestionnaireData({
        variables: {
          filter: questionnaireFilter,
          where: {
            type: [QUESTIONNAIRE_TYPE?.ROQ_PATH],
            ...(filters && { ...filters }),
          },
        },
      });
    }
  };

  const renderActionButtons = (editQuestionnaireData) => (
    <div className="d-flex flex-vertical">
      <AccessControl
        allowedPermissions={[
          'FET_QUESTIONNAIRE_UPDATE',
          'FET_QUESTIONNAIRE_VIEW',
        ]}
      >
        <Button
          id="questionnaire-table-edit-btn"
          className="b-0"
          onClick={() => handleAddEditQuestionnaire(editQuestionnaireData)}
        >
          Edit
        </Button>
      </AccessControl>
      {((editQuestionnaireData?.sponsorName && isSponsor) ||
        (!editQuestionnaireData?.sponsorName && !isSponsor)) && (
        <>
          <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_DELETE']}>
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDeleteQuestionnaire(editQuestionnaireData)}
              okText="Yes"
              cancelText="No"
            >
              <Button id="questionnaire-table-status-btn" className="b-0">
                Remove
              </Button>
            </Popconfirm>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
            <Popconfirm
              title={`Are you sure to ${
                editQuestionnaireData?.isActive
                  ? 'Mark Inactive'
                  : 'Mark Active'
              }?`}
              onConfirm={() => handleQuestionnaireStatus(editQuestionnaireData)}
              okText="Yes"
              cancelText="No"
            >
              <Button id="questionnaire-table-status-btn" className="b-0">
                {editQuestionnaireData?.isActive
                  ? 'Mark Inactive'
                  : 'Mark Active'}
              </Button>
            </Popconfirm>
          </AccessControl>
        </>
      )}
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_CREATE']}>
        <Popconfirm
          title="Are you sure to duplicate?"
          onConfirm={() => handleDuplicateQuestionnaire(editQuestionnaireData)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            id="secondary-questionnaire-table-duplicate-btn"
            className="b-0"
          >
            Duplicate
          </Button>
        </Popconfirm>
      </AccessControl>
    </div>
  );

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      ellipsis: true,
      fixed: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'title' && sortedInfo?.order,
    },
    !isSponsor && {
      title: 'SPONSOR',
      dataIndex: 'sponsorName',
      key: 'sponsorName',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'sponsorName' && sortedInfo?.order,
      width: 140,
      ...filterPopup('sponsorName'),
      render: (sponsorName) => <span>{sponsorName || '-'}</span>,
    },
    {
      title: 'STATUS',
      width: 120,
      dataIndex: 'isActive',
      key: 'isActive',
      ...filterPopup('isActive'),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      },
    },
    checkPermissions(permissions, [
      'FET_QUESTIONNAIRE_UPDATE',
      'FET_QUESTIONNAIRE_DELETE',
      'FET_QUESTIONNAIRE_VIEW',
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => (
        <Popover
          placement="bottom"
          overlayClassName="action-button"
          content={renderActionButtons(record)}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  const onSearchChange = (value) => {
    setQuestionnaireFilter({
      ...questionnaireFilter,
      skip: value
        ? 0
        : questionnaireFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    setDataLoading(true);
    fetchQuestionnaireData({
      variables: {
        filter: {
          ...questionnaireFilter,
          skip: value
            ? 0
            : questionnaireFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        where: {
          type: [QUESTIONNAIRE_TYPE?.ROQ_PATH],
          ...(filters && { ...filters }),
        },
      },
    });
  };

  return (
    <div>
      {showModal && (
        <SettingModal showModal={showModal} setShowModal={setShowModal} />
      )}
      {showSecondaryModal && (
        <SecondarySettingModal
          showModal={showSecondaryModal}
          setShowModal={setShowSecondaryModal}
        />
      )}
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_CREATE']}>
        <Portal portalId="header-right-content">
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <div className="d-flex flex-vertical ml-8">
                      <span className="menu-title">ROQ Builder</span>
                      <span className="font-size-10">Description</span>
                    </div>
                  ),
                  icon: <QuestionnaireIconComponent />,
                  onClick: () => setShowModal(true),
                },
                {
                  label: (
                    <div className="d-flex flex-vertical ml-8">
                      <span className="menu-title">ROQ Path</span>
                      <span className="font-size-10">Description</span>
                    </div>
                  ),
                  icon: <SecondaryQuestionnaireIcon />,
                  onClick: () => setShowSecondaryModal(true),
                },
              ],
            }}
          >
            <Button
              className="common-button"
              icon={<PlusOutlined />}
              size="small"
              id="questionnaire-table-add-btn"
              type="primary"
            >
              Add Questionnaire
            </Button>
          </Dropdown>
        </Portal>
      </AccessControl>
      <AccessControl
        allowedPermissions={['FET_QUESTIONNAIRE_LIST']}
        showNoAccess
      >
        <div className="mb-16 d-flex justify-between align-center">
          <div className="fill-width search-checkbox">
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Questionnaire or other detail.."
              name="Questionnaires"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
        </div>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={
                loading ||
                updateQuestionnaireLoading ||
                deleteQuestionnaireLoading ||
                copyQuestionnaireLoading
              }
              columns={[...columns?.filter((item) => item !== false)]}
              loading={dataLoading}
              data={data?.questionnaires?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default SecondaryQuestionnaireTable;
