import { Button, Col, Divider, Form, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import { ROUTES } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import EditorComponent from '../../../components/EditorComponent';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const { Option } = SelectComponent;

const ProductCategoryForm = (props) => {
  const {
    state: { permissions, isSponsor },
    dispatch,
  } = useContext(AppContext);
  const {
    productCategoryData = null,
    handleSubmit,
    isSubmit = false,
    isEdit = false,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [form] = Form?.useForm();

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  useEffect(() => {
    const unListen = history?.listen((historyProps) => {
      if (historyProps?.action === 'POP') {
        navigate(historyProps?.location?.pathname, {
          state: { ...location?.state },
        });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  return (
    <div className="content-section">
      <AccessControl
        allowedPermissions={
          isEdit
            ? ['FET_PRODUCT_CATEGORY_UPDATE']
            : ['FET_PRODUCT_CATEGORY_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="productCategory-table-discard-btn"
            onClick={() =>
              navigate(ROUTES?.PRODUCT_CATEGORIES, {
                state: { ...location?.state },
              })
            }
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            disabled={
              !isSponsor && productCategoryData?.sponsorName?.length > 0
            }
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="productcategory-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={productCategoryData}
        disabled={productCategoryData?.sponsorName && !isSponsor}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit
                ? ['FET_PRODUCT_CATEGORY_UPDATE']
                : ['FET_PRODUCT_CATEGORY_CREATE'],
            )
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider " />
          <Row gutter={16} className="required-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Category Title' },
                ]}
                name="title"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status',
                  },
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <span className="form-divider-text">OPTIONAL</span>
          <Divider className="form-divider optional-divider" />
          <Row gutter={16}>
            {!isSponsor && productCategoryData?.sponsorName && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="sponsorName" label="Sponsor">
                  <InputComponent disabled />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" label="Description">
                <EditorComponent
                  disabled={productCategoryData?.sponsorName && !isSponsor}
                  placeholder="Enter description"
                />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default ProductCategoryForm;
