import { gql } from '@apollo/client';

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const BATCH_FILTER = gql`
  query batchFilters($filter: BatchFilter!) {
    batchFilters(filter: $filter) {
      count
      data {
        name
        description
        refData
        isSchedule
        scheduleDate
        isEmailNotificationEnabled
        emailNotificationConfig
        started
        completed
        industryId
        lineOfBusinessId
        tenantIds
        sponsorId
        status
        id
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        label
        alias
        industryCode
        refData
        key
        tenantId
        description
        isActive
        referenceId
        id
      }
    }
  }
`;

export const LINE_OF_BUSINESS = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const GET_TENANTS = gql`
  query sponsorAssociatedTenants(
    $where: TenantsWhereInput
    $filter: TenantFilter!
  ) {
    sponsorAssociatedTenants(where: $where, filter: $filter) {
      count
      data {
        id
        name
        email
      }
    }
  }
`;

export const GET_ENTITIES = gql`
  query entities($filter: EntityFilter!) {
    entities(filter: $filter) {
      count
      data {
        name
      }
    }
  }
`;

export const GET_ENTITY_FIELDS = gql`
  query entityFields($filter: EntityFilter!, $where: EntitiesWhereFilter!) {
    entityFields(filter: $filter, where: $where) {
      count
      data {
        name
        fieldName
        fieldType
        label
        isAllowed
        query
        tenantId
        sponsorId
        data
        id
      }
    }
  }
`;

export const GET_BATCHES = gql`
  query batches($filter: BatchFilter!, $where: BatchesWhereFilter) {
    batches(filter: $filter, where: $where) {
      count
      data {
        id
        name
        description
        refData
        isSchedule
        scheduleDate
        isEmailNotificationEnabled
        emailNotificationConfig
        started
        completed
        industry {
          id
          label
        }
        industryId
        lineOfBusiness {
          id
          label
        }
        lineOfBusinessId
        tenantIds
        sponsorId
        status
        batchCode
        pickListStatus {
          id
          key
          label
        }
        createdAt
      }
    }
  }
`;

export const GET_BATCH = gql`
  query batch($where: BatchWhereInput!) {
    batch(where: $where) {
      name
      lineOfBusinessId
    }
  }
`;

export const GET_BATCH_ENTITIES = gql`
  query batchEntities(
    $filter: BatchEntityFilter!
    $where: BatchEntitiesWhereFilter!
  ) {
    batchEntities(filter: $filter, where: $where) {
      count
      data {
        entityName
        conditions
        totalRecords
        processedRecords
        failedRecords
        status
        tenantId
        sponsorId
        id
        pickListBatchStatus {
          id
          key
        }
        pickListStatus {
          id
          key
          label
        }
        children
      }
    }
  }
`;

export const GET_BATCH_ENTITY_RECORDS = gql`
  query batchEntityRecords(
    $filter: BatchEntityFilter
    $where: batchEntityRecordWhere!
  ) {
    batchEntityRecords(filter: $filter, where: $where)
  }
`;

export const GET_BATCH_ENTITY_FILTER = gql`
  query batchEntityFilters($filter: BatchEntityFilter!) {
    batchEntityFilters(filter: $filter) {
      count
      data {
        entityName
        conditions
        totalRecords
        processedRecords
        failedRecords
        status
        tenantId
        sponsorId
        id
      }
    }
  }
`;
