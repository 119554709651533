import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import CampaignForm from '../components/CampaignForm';
import { UPDATE_CAMPAIGN } from '../graphql/Mutations';
import { GET_CAMPAIGN } from '../graphql/Queries';

function CampaignEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  const { data: { campaign } = {} } = useQuery(GET_CAMPAIGN, {
    variables: { id },
    onCompleted: (res) => {
      if (res?.campaign) {
        setLoading(false);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  const [updateCampaignMutate] = useMutation(UPDATE_CAMPAIGN, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues,
      subAreaId: formValues.subAreaId || null,
    };

    delete newFormValues?.sponsorName;

    const variables = {
      data: newFormValues,
      where: { id: campaign?.id },
    };

    try {
      const response = await updateCampaignMutate({
        variables: { ...variables },
      });
      if (response?.data?.updateCampaign) {
        setSubmitLoading(false);
        navigate(ROUTES.CAMPAIGNS, { state: { ...location?.state } });
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Navigate to={ROUTES?.CAMPAIGNS} replace />;
  }

  const initialValues = {
    ...campaign,
    status: campaign?.status,
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CAMPAIGNS} />
          {campaign && <span className="portal-header">{campaign?.name}</span>}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <CampaignForm
          isEdit
          campaignData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      )}
    </Card>
  );
}

export default CampaignEdit;
