import {
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Empty,
  Radio,
  Row,
  Switch,
  Tag,
} from 'antd';
import {
  debounce,
  filter,
  findIndex,
  forEach,
  isEmpty,
  map,
  set,
} from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { messageContext } from '../../../app/components/AppContextHolder';
import { AppContext } from '../../../AppContext';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import {
  BIG_SCREEN,
  CONDITIONS_KEYS,
  MODAL_WIDTH,
  QUOTE_MODIFIER_CONDITION_ATTRIBUTE,
  QUOTE_MODIFIER_NUMERIC_CONDITIONS,
  SKIP_RECORD,
  SMALL_TABLET,
  TABLET_BREAKPOINT,
} from '../../../common/constants';
import { displayZipCodes } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import PriceComponent from '../../../components/PriceComponent';
import SearchComponent from '../../../components/SearchComponent';
import SelectComponent from '../../../components/SelectComponent';
import {
  CONVERSION_PARAMETER_LIST,
  GET_BRANDS,
  GET_MANUFACTURERS,
  GET_PRICING_MODIFIER_REGIONS,
} from '../graphql/Queries';

const { Option } = SelectComponent;

const NONE_UNIT_OF_PARAM_KEY = 'NONE';

let conversionParameterScrollDebounceJob;
const conversionParameterFilter = {
  skip: 0,
  limit: 20,
  search: '',
};

const PricingConditionModal = ({
  showModal = false,
  setShowModal,
  selectedCondition = null,
  setConditionDataCopy,
  conditionDataCopy = {},
  setCollapseActiveKeys,
  attributeLoading = false,
  setAttributeLoading,
  attributesData = [],
  collapseActiveKeys = [],
  selectedIndustryData = [],
  hasAttribute = false,
  setHasAttribute,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const [regionSearchValue, setRegionSearchValue] = useState('');
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [conditionData, setConditionData] = useState({});
  const [regionsData, setRegionsData] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState([]);
  const [conversionParamObj, setConversionParamObj] = useState(null);
  const [industryId, setIndustryId] = useState(null);
  const [lineOfBusinessId, setLineOfBusinessId] = useState(null);

  const [conversionParameterLoading, setConversionParameterLoading] = useState(
    false,
  );
  const [
    conversionParameterSearchFlag,
    setConversionParameterSearchFlag,
  ] = useState(false);
  const [conversionParameterData, setConversionParameterData] = useState([]);
  const [conversionParameterIsEnd, setConversionParameterIsEnd] = useState(
    false,
  );
  const [
    conversionParameterDebounceCall,
    setConversionParameterDebounceCall,
  ] = useState(0);

  const [errorObject, setErrorObject] = useState(null);

  const {
    state: { windowWidth },
  } = useContext(AppContext);

  let showZip;
  if (windowWidth < SMALL_TABLET) {
    showZip = 8;
  } else if (windowWidth < TABLET_BREAKPOINT) {
    showZip = 75;
  } else if (windowWidth < BIG_SCREEN) {
    showZip = 12;
  } else {
    showZip = 15;
  }

  const [brands, { data: brandsData, loading: brandsLoading }] = useLazyQuery(
    GET_BRANDS,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
    },
  );

  const [regionPricingModifier, { data: regionPricingData }] = useLazyQuery(
    GET_PRICING_MODIFIER_REGIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const regionsCopy = [];
        forEach(res?.regionPricingModifier?.data, (item) => {
          regionsCopy?.push(item);
          if (item?.subRegions?.length > 0) {
            forEach(item?.subRegions, (data) => regionsCopy?.push(data));
          }
        });
        setRegionsData(regionsCopy);
        setRegionsLoading(false);
      },
      onError: () => {
        setRegionsLoading(false);
      },
    },
  );

  const [
    manufacturers,
    { data: manufacturersData, loading: manufacturersLoading },
  ] = useLazyQuery(GET_MANUFACTURERS, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const [getConversionParameterList] = useLazyQuery(CONVERSION_PARAMETER_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const filteredParameters = res?.unitInputParameter?.data?.filter(
        (param) => param.key !== NONE_UNIT_OF_PARAM_KEY,
      );
      setConversionParameterIsEnd(filteredParameters?.length < SKIP_RECORD);

      if (conversionParameterSearchFlag) {
        setConversionParameterData([...filteredParameters]);
      } else {
        setConversionParameterData([
          ...conversionParameterData,
          ...filteredParameters,
        ]);
      }
      setConversionParameterLoading(false);
    },
    onError() {
      setConversionParameterLoading(false);
    },
  });

  const handleAttributeSelect = (value) => {
    if (value) {
      setSelectedAttribute(value);
      setAttributeLoading(false);
      if (!conditionData?.industry?.[0]?.lineOfBusiness?.length) {
        setConditionData({ ...conditionData, industry: [] });
      }
      switch (value) {
        case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_BRAND:
          brands({
            variables: {
              filter: {
                sortOn: 'createdAt',
                sortBy: 'DESC',
                defaultBrand: true,
              },
              where: { isActive: true },
            },
          });
          break;

        case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION:
          setRegionsLoading(true);
          regionPricingModifier({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'DESC',
              },
              where: { isActive: true },
            },
          });
          break;

        case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_MANUFACTURER:
          manufacturers({
            variables: {
              filter: {
                sortOn: 'createdAt',
                sortBy: 'DESC',
              },
              where: { isActive: true },
            },
          });
          break;

        case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_UNIT_INPUT_PARAMETER:
          getConversionParameterList({
            variables: {
              filter: {
                ...conversionParameterFilter,
              },
              where: {
                industryId,
                lineOfBusinessId,
              },
            },
          });

          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (conditionDataCopy) {
      setConditionData(conditionDataCopy);
      setConversionParamObj({
        condition: conditionDataCopy?.unitInputParameter?.condition,
      });
    }
    setSelectedAttribute(
      selectedCondition || QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_BRAND,
    );

    setIndustryId(selectedIndustryData?.[0]?.id || null);
    setLineOfBusinessId(
      selectedIndustryData?.[0]?.lineOfBusiness?.[0]?.id || null,
    );
    switch (selectedCondition) {
      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_BRAND:
        brands({
          variables: {
            filter: {
              sortOn: 'createdAt',
              sortBy: 'DESC',
              defaultBrand: true,
            },
            where: { isActive: true },
          },
        });
        break;

      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION:
        setRegionsLoading(true);
        regionPricingModifier({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'DESC',
            },
            where: { isActive: true },
          },
        });
        break;

      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_MANUFACTURER:
        manufacturers({
          variables: {
            filter: {
              sortOn: 'createdAt',
              sortBy: 'DESC',
            },
            where: { isActive: true },
          },
        });
        break;

      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_UNIT_INPUT_PARAMETER:
        getConversionParameterList({
          variables: {
            filter: {
              ...conversionParameterFilter,
            },
            where: {
              industryId: selectedIndustryData?.[0]?.id,
              lineOfBusinessId:
                selectedIndustryData?.[0]?.lineOfBusiness?.[0]?.id,
            },
          },
        });
        break;

      default:
        brands({
          variables: {
            filter: {
              sortOn: 'createdAt',
              sortBy: 'DESC',
              defaultBrand: true,
            },
            where: { isActive: true },
          },
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCheckboxBrands = (e, record) => {
    let brandsCopy = [...conditionData?.brands];
    if (e?.target?.checked) {
      brandsCopy?.push({ id: record?.id, label: record?.name });
    } else {
      brandsCopy = filter(brandsCopy, (item) => item?.id !== record?.id);
    }
    setConditionData({ ...conditionData, brands: brandsCopy });
  };

  const onCheckAllChangeBrands = (e) => {
    if (e?.target.checked) {
      const brandsCopy = [];
      forEach(brandsData?.brands?.data, (item) =>
        brandsCopy?.push({
          id: item?.id,
          label: item?.name,
        }),
      );
      setConditionData({ ...conditionData, brands: brandsCopy });
    } else {
      setConditionData({ ...conditionData, brands: [] });
    }
  };

  const handleChangeCheckboxManufacturers = (e, record) => {
    let manufacturersCopy = [...conditionData?.manufacturers];
    if (e?.target?.checked) {
      manufacturersCopy?.push({ id: record?.id, label: record?.name });
    } else {
      manufacturersCopy = filter(
        manufacturersCopy,
        (item) => item?.id !== record?.id,
      );
    }
    setConditionData({ ...conditionData, manufacturers: manufacturersCopy });
  };

  const onCheckAllChangeManufacturers = (e) => {
    if (e?.target.checked) {
      const manufacturersCopy = [];
      forEach(manufacturersData?.manufacturers?.data, (item) =>
        manufacturersCopy?.push({
          id: item?.id,
          label: item?.name,
        }),
      );
      setConditionData({ ...conditionData, manufacturers: manufacturersCopy });
    } else {
      setConditionData({ ...conditionData, manufacturers: [] });
    }
  };

  const onSearchChange = (value) => {
    setRegionSearchValue(value);
    setRegionsLoading(true);
    regionPricingModifier({
      variables: {
        filter: {
          sortOn: 'name',
          sortBy: 'DESC',
          search: value,
        },
        where: { isActive: true },
      },
    });
  };

  const handleClickZip = (e, zipCode, region) => {
    e?.stopPropagation();
    const regionCopy = [...conditionData?.regions];
    const regionIndex = findIndex(
      regionCopy,
      (item) => item?.id === region?.id,
    );
    if (regionIndex > -1) {
      const zipCodeIndex = findIndex(
        regionCopy?.[regionIndex]?.zipCodes,
        (item) => item === zipCode,
      );
      if (zipCodeIndex > -1) {
        regionCopy?.[regionIndex]?.zipCodes?.splice(zipCodeIndex, 1);
        if (!regionCopy?.[regionIndex]?.zipCodes?.length) {
          regionCopy?.splice(regionIndex, 1);
        }
        set(regionCopy?.[regionIndex], 'all', false);
      } else {
        regionCopy?.[regionIndex]?.zipCodes?.push(zipCode);
        if (
          regionCopy?.[regionIndex]?.zipCodes?.length ===
          region?.zipCodes?.length
        ) {
          set(regionCopy?.[regionIndex], 'all', true);
        }
      }
    } else {
      regionCopy?.push({
        id: region?.id,
        label: region?.name,
        zipCodes: [zipCode],
        all: region?.zipCodes?.length === 1,
      });
    }
    setConditionData({ ...conditionData, regions: regionCopy });
  };

  const onCheckAllChangeRegions = (e, item) => {
    e?.stopPropagation();
    const regionCopy = [...conditionData?.regions];
    const regionIndex = findIndex(
      regionCopy,
      (region) => region?.id === item?.id,
    );
    if (e?.target?.checked) {
      const zipCodes = [];
      forEach(item?.zipCodes, (zipCode) => zipCodes?.push(zipCode));
      if (regionIndex > -1) {
        regionCopy[regionIndex].zipCodes = zipCodes;
        set(regionCopy?.[regionIndex], 'all', true);
      } else {
        regionCopy?.push({
          id: item?.id,
          label: item?.name,
          zipCodes,
          all: true,
        });
      }
    } else {
      regionCopy?.splice(regionIndex, 1);
      set(regionCopy?.[regionIndex], 'all', false);
    }
    setConditionData({ ...conditionData, regions: regionCopy });
  };

  const handleDeleteTag = (regionIndex, zipIndex) => {
    const regionCopy = [...conditionData?.regions];
    if (zipIndex?.toString()?.length > 0) {
      regionCopy?.[regionIndex]?.zipCodes?.splice(zipIndex, 1);
      if (!regionCopy?.[regionIndex]?.zipCodes?.length) {
        regionCopy?.splice(regionIndex, 1);
      }
    } else {
      regionCopy?.splice(regionIndex, 1);
    }
    setConditionData({ ...conditionData, regions: regionCopy });
  };

  const renderRegionHeader = (item, regionCopy, regionIndex) => (
    <div className="panel-title">
      <div className="width-percent-30">
        <Checkbox
          className={`common-checkbox collapse-checkbox ${
            !item?.subRegions ? 'left-space' : ''
          }`}
          indeterminate={
            regionCopy?.[regionIndex]?.zipCodes?.length > 0 &&
            regionCopy?.[regionIndex]?.zipCodes?.length !==
              item?.zipCodes?.length
          }
          checked={
            regionCopy?.[regionIndex]?.zipCodes?.length > 0 &&
            regionCopy?.[regionIndex]?.zipCodes?.length ===
              item?.zipCodes?.length
          }
          onClick={(e) => e?.stopPropagation()}
          onChange={(e) => onCheckAllChangeRegions(e, item)}
        >
          <span onClick={(e) => e?.stopPropagation()}>{item?.name}</span>
        </Checkbox>
      </div>
      <div className="width-percent-70">
        {map(item?.zipCodes?.slice(0, showZip), (zip) => (
          <Tag
            key={zip}
            title={zip}
            className={`single-item ${
              regionCopy?.[regionIndex]?.zipCodes?.includes(zip)
                ? 'zip-highlight'
                : ''
            }`}
            onClick={(e) => handleClickZip(e, zip, item)}
          >
            <span className="content-text">{displayZipCodes(zip)}</span>
          </Tag>
        ))}
      </div>
    </div>
  );

  const addSingleZipCode = () => {
    const regionCopy = [...conditionData?.regions];
    let isAlready = false;
    forEach(regionCopy, (item) => {
      if (!item?.id && !item?.label) {
        if (item?.zipCodes?.[0] === regionSearchValue) {
          isAlready = true;
        }
      }
    });
    if (!isAlready) {
      regionCopy?.push({
        id: null,
        label: null,
        zipCodes: [regionSearchValue],
      });
      setConditionData({ ...conditionData, regions: regionCopy });
    } else {
      messageContext?.destroy();
      messageContext?.error('Already Postal code is there.');
    }
  };

  const handleAddConditions = () => {
    let errorObjectClone = null;
    if (
      !collapseActiveKeys?.includes(
        QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION,
      )
    ) {
      setCollapseActiveKeys([
        ...collapseActiveKeys,
        QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION,
      ]);
    }
    if (
      selectedAttribute ===
      QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_UNIT_INPUT_PARAMETER
    ) {
      const {
        conversionParameterId = null,
        condition = CONDITIONS_KEYS?.NOT_EQUAL,
        value = null,
        lessThanCheck = false,
        greaterThanValue = null,
        greaterThanCheck = false,
        lessThanValue = null,
      } = conditionData?.unitInputParameter || {};

      if (!conversionParameterId) {
        messageContext?.destroy();
        messageContext?.error('Please select conversion parameter.');
        return;
      }
      if (condition === CONDITIONS_KEYS?.GREATER_LESS_RANGE) {
        if (lessThanCheck && !lessThanValue) {
          errorObjectClone = {
            lessThanValue: 'Please enter less than value.',
          };
          setErrorObject({ ...errorObjectClone });
        }
        if (greaterThanCheck && !greaterThanValue) {
          errorObjectClone = {
            greaterThanValue: 'Please enter greater than value.',
          };
          setErrorObject({ ...errorObjectClone });
        }
        if (greaterThanCheck && lessThanCheck) {
          if (greaterThanValue === lessThanValue) {
            errorObjectClone = {
              greaterThanValue:
                'Greater than and less than value can not be the same.',
              lessThanValue:
                'Greater than and less than value can not be the same.',
            };
            setErrorObject({ ...errorObjectClone });
          }
          if (greaterThanValue > lessThanValue) {
            errorObjectClone = {
              greaterThanValue: `Should be less than ${lessThanValue}`,
              lessThanValue: `Should be greater than ${greaterThanValue}`,
            };
            setErrorObject({ ...errorObjectClone });
          }
          if (!greaterThanValue && !lessThanValue) {
            errorObjectClone = {
              greaterThanValue: `Greater than value is required`,
              lessThanValue: `Less than value is required`,
            };
            setErrorObject({ ...errorObjectClone });
          }
          if (!greaterThanValue) {
            errorObjectClone = {
              greaterThanValue: `Greater than value is required`,
            };
            setErrorObject({ ...errorObjectClone });
          }

          if (!lessThanValue) {
            errorObjectClone = {
              lessThanValue: `Less than value is required`,
            };
            setErrorObject({ ...errorObjectClone });
          }
        }
        if (!greaterThanValue && !lessThanValue) {
          messageContext?.destroy();
          messageContext?.error(
            'Please enter less than value or greater than value.',
          );
          return;
        }

        if (!isEmpty(errorObjectClone)) {
          return;
        }
      }
      if (
        condition === CONDITIONS_KEYS?.EQUAL ||
        condition === CONDITIONS_KEYS?.NOT_EQUAL
      ) {
        if (!value) {
          errorObjectClone = {
            value: 'Please enter the value.',
          };
          setErrorObject({ ...errorObjectClone });
          return;
        }
        setShowModal(false);
      }
      setShowModal(false);
    }
    setShowModal(false);
    setHasAttribute(false);
    setConditionDataCopy(conditionData);
  };

  const selectedLength = () => {
    switch (selectedAttribute) {
      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_BRAND:
        return conditionData?.brands?.length;
      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION:
        return conditionData?.regions?.length;
      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_MANUFACTURER:
        return conditionData?.manufacturers?.length;
      default:
        break;
    }
  };

  const handleConversionParameterChange = (value) => {
    setConversionParameterSearchFlag(true);
    setSearchValue(value);
    setConversionParameterLoading(true);

    getConversionParameterList({
      variables: {
        filter: {
          ...conversionParameterFilter,
          search: value,
        },
        where: {
          industryId: selectedIndustryData?.[0]?.id,
          lineOfBusinessId: selectedIndustryData?.[0]?.lineOfBusiness?.[0]?.id,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedConversionParameterHandler = useCallback(
    debounce(handleConversionParameterChange, 500),
    [],
  );

  const handleConversionParameterClear = () => {
    let unitInputParamCopy = { ...conditionData?.unitInputParameter };
    unitInputParamCopy = {
      ...unitInputParamCopy,
      conversionParameterId: null,
      conversionParaMeterName: null,
      unitOfMeasureTitle: null,
      conversionParaMeterKey: null,
      defaultUnitOfMeasureId: null,
    };
    setConditionData({
      ...conditionData,
      unitInputParameter: unitInputParamCopy,
    });
    setConversionParameterData([]);
    getConversionParameterList({
      variables: {
        filter: conversionParameterFilter,
        where: {
          industryId,
          lineOfBusinessId,
        },
      },
    });
  };

  const handleConversionParameterBlur = () => {
    setSearchValue('');
    setConversionParameterIsEnd(false);
    setConversionParameterDebounceCall(0);
  };

  const onConversionParameterScroll = (event) => {
    setConversionParameterSearchFlag(false);
    if (conversionParameterScrollDebounceJob) {
      conversionParameterScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    conversionParameterScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !conversionParameterIsEnd) {
        setConversionParameterLoading(true);
        setConversionParameterDebounceCall((prevState) => prevState + 1);
        getConversionParameterList({
          variables: {
            filter: {
              ...conversionParameterFilter,
              skip: (conversionParameterDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
            where: { industryId, lineOfBusinessId },
          },
        });
      }
    }, 500);

    conversionParameterScrollDebounceJob();
  };

  const handleConversionParameterSelect = (value, ...rest) => {
    const conversionParaMeterName = rest?.[0]?.['data-name'];
    const unitOfMeasureTitle = rest?.[0]?.['data-units-of-measure-title'];
    const conversionParaMeterKey = rest?.[0]?.['data-key'];
    const defaultUnitOfMeasureId = rest?.[0]?.['data-units-of-measure-id'];

    let unitInputParamCopy = { ...conditionData?.unitInputParameter };
    unitInputParamCopy = {
      ...unitInputParamCopy,
      conversionParameterId: value,
      conversionParaMeterName,
      unitOfMeasureTitle,
      conversionParaMeterKey,
      defaultUnitOfMeasureId,
    };
    setConditionData({
      ...conditionData,
      unitInputParameter: unitInputParamCopy,
    });
  };

  const handleRadioGroupChange = (event) => {
    let unitInputParamCopy = { ...conditionData?.unitInputParameter };
    setConversionParamObj(null); // empty object
    setConversionParamObj({
      condition: event?.target?.value,
    }); // added new condition

    unitInputParamCopy = {
      ...unitInputParamCopy,
      condition: event?.target?.value,
      value: null,
      lessThanValue: null,
      lessThanCheck: false,
      greaterThanValue: null,
      greaterThanCheck: false,
    };
    setConditionData({
      ...conditionData,
      unitInputParameter: unitInputParamCopy,
    });
  };

  const handleConditionSelect = (event, rest) => {
    setConversionParameterData([]);
    setHasAttribute(rest?.['data-has-attribute'] || false);
  };

  const onSwitchChanges = (checked, type) => {
    let unitInputParamCopy = { ...conditionData?.unitInputParameter };
    unitInputParamCopy = {
      ...unitInputParamCopy,
      [type]: checked,
    };
    setConditionData({
      ...conditionData,
      unitInputParameter: unitInputParamCopy,
    });
  };

  const handleNumberChange = (value, type) => {
    // add condition for reset number
    let unitInputParamCopy = { ...conditionData?.unitInputParameter };
    setErrorObject({});
    // commented this code could be used in future
    // if (!isEmpty(errorObject)) {
    //   const errorObjClone = { ...errorObject };
    //   delete errorObjClone[type];
    //   setErrorObject({ ...errorObjClone });
    // }
    unitInputParamCopy = {
      ...unitInputParamCopy,
      [type]: parseFloat(value),
    };
    setConditionData({
      ...conditionData,
      unitInputParameter: unitInputParamCopy,
    });
  };

  const handleVariableRateChange = (e) => {
    let unitInputParamCopy = { ...conditionData?.unitInputParameter };

    unitInputParamCopy = {
      ...unitInputParamCopy,
      variableRateCheck: e?.target?.checked,
    };
    setConditionData({
      ...conditionData,
      unitInputParameter: unitInputParamCopy,
    });
  };

  const regionPanelItems = map(regionsData, (item) => {
    const regionCopy = [...conditionData?.regions];
    const regionIndex = findIndex(
      regionCopy,
      (region) => region?.id === item?.id,
    );
    return {
      className: item?.subRegions
        ? 'panel-height parent-region'
        : 'panel-height sub-region',
      label: renderRegionHeader(item, regionCopy, regionIndex),
      collapsible: item?.zipCodes?.length < showZip && 'disabled',
      showArrow: !(item?.zipCodes?.length < showZip),
      key: item?.id,
      children: (
        <>
          {map(item?.zipCodes?.slice(showZip), (zip) => (
            <Tag
              key={zip}
              title={zip}
              className={`single-item ${
                regionCopy?.[regionIndex]?.zipCodes?.includes(zip)
                  ? 'zip-highlight'
                  : ''
              }`}
              onClick={(e) => handleClickZip(e, zip, item)}
            >
              <span className="content-text">{displayZipCodes(zip)}</span>
            </Tag>
          ))}
        </>
      ),
    };
  });

  return (
    <ModalComponent
      width={MODAL_WIDTH}
      forceRender
      title="ADD CONDITION"
      open={showModal}
      onCancel={() => {
        if (isEmpty(errorObject)) {
          setShowModal(false);
        }
      }}
      destroyOnClose
      wrapClassName="pricing-modifier-modal"
      footer={
        <div
          className={`d-flex ${
            selectedAttribute === QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION
              ? 'fill-width justify-between align-end'
              : 'justify-end'
          }`}
        >
          {selectedAttribute ===
            QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION && (
            <div className="d-flex flex-vertical">
              <span className="no-results">
                Adding the following Regions & POSTAL Codes
              </span>
              <div className="d-flex flex-wrap">
                {map(conditionData?.regions, (singleData, tagIndex) => {
                  if (singleData?.all) {
                    return (
                      <Tag
                        className="single-item region-tags"
                        title={singleData?.label}
                        key={singleData?.id}
                      >
                        <span className="content-text">
                          <span className="region-only">
                            {singleData?.label}
                          </span>
                          <CloseOutlined
                            className="close-img"
                            onClick={() => handleDeleteTag(tagIndex)}
                          />
                        </span>
                      </Tag>
                    );
                  }
                  return map(singleData?.zipCodes, (item, index) => (
                    <Tag
                      className="single-item region-tags"
                      title={`${
                        singleData?.label ? `${singleData?.label} - ` : ''
                      }${item}`}
                      key={`${item}${tagIndex}`}
                    >
                      <span className="content-text">
                        <span>{`${
                          singleData?.label ? `${singleData?.label} - ` : ''
                        }${item}`}</span>
                        <CloseOutlined
                          className="close-img"
                          onClick={() => handleDeleteTag(tagIndex, index)}
                        />
                      </span>
                    </Tag>
                  ));
                })}
              </div>
            </div>
          )}
          <Button
            type="primary"
            disabled={selectedLength() < 1}
            className="common-button add-condition-button"
            onClick={handleAddConditions}
          >
            Add
          </Button>
        </div>
      }
    >
      <Row>
        <Col span={24}>
          <SelectComponent
            allowClear={false}
            className="fill-width"
            placeholder="Select Attribute"
            notFoundContent={
              attributeLoading ? (
                <LoaderComponent size="small" setHeight={10} />
              ) : (
                <Empty
                  className="no-data-image"
                  image={Empty?.PRESENTED_IMAGE_SIMPLE}
                />
              )
            }
            value={selectedAttribute}
            onSelect={handleConditionSelect}
            onChange={handleAttributeSelect}
          >
            {map(attributesData, (item) => (
              <Option
                key={item?.id}
                value={item?.key}
                data-has-attribute={item?.hasAttributes}
              >
                {item?.key === QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_BRAND
                  ? 'Retailer'
                  : item?.label}
              </Option>
            ))}
          </SelectComponent>
        </Col>
      </Row>
      {selectedAttribute === QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_BRAND && (
        <>
          {brandsLoading ? (
            <LoaderComponent setHeight={75} />
          ) : (
            <>
              {brandsData?.brands?.data?.length > 0 ? (
                <div>
                  <Checkbox
                    className="common-checkbox check-all"
                    indeterminate={
                      !!(
                        conditionData?.brands?.length > 0 &&
                        conditionData?.brands?.length !==
                          brandsData?.brands?.data?.length
                      )
                    }
                    checked={
                      conditionData?.brands?.length ===
                      brandsData?.brands?.data?.length
                    }
                    onChange={onCheckAllChangeBrands}
                  >
                    ALL RETAILERS
                  </Checkbox>
                  <div className="center-content">
                    <Checkbox.Group
                      value={map(conditionData?.brands, (item) => item?.id)}
                      className="fill-width"
                    >
                      <Row gutter={[0, 16]} align="middle">
                        {map(brandsData?.brands?.data, (item) => (
                          <Col span={8} key={item?.id}>
                            <Checkbox
                              className="common-checkbox"
                              value={item?.id}
                              onChange={(e) =>
                                handleChangeCheckboxBrands(e, item)
                              }
                            >
                              <div>
                                <img
                                  src={item?.logo?.url || placeholderImage}
                                  alt="retailer-img"
                                  onError={(e) => {
                                    e.target.src = placeholderImage;
                                  }}
                                  className="list-img"
                                  width={24}
                                  height={24}
                                />
                                <span>{item?.name}</span>
                              </div>
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </div>
                </div>
              ) : (
                <Empty
                  className="no-data-image"
                  image={Empty?.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </>
          )}
        </>
      )}
      {selectedAttribute === QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION && (
        <>
          <div className="check-all">
            <div className="fill-width search-checkbox">
              <SearchComponent
                className="list-search-box"
                id="search-container-id"
                placeholder="Region, Sub Region, or ZIP Code"
                name="Regions"
                getData={onSearchChange}
              />
            </div>
            {regionsLoading ? (
              <LoaderComponent setHeight={75} />
            ) : (
              <>
                {regionsData?.length > 0 ? (
                  <div className="region-margin center-content">
                    <Collapse
                      bordered={false}
                      expandIconPosition="end"
                      expandIcon={({ isActive }) => (
                        <UpOutlined rotate={isActive ? 0 : 180} />
                      )}
                      className="common-collapse collapse-text"
                      items={regionPanelItems}
                    />
                    {/* {map(regionsData, (item) => {
                        const regionCopy = [...conditionData?.regions];
                        const regionIndex = findIndex(
                          regionCopy,
                          (region) => region?.id === item?.id,
                        );
                        return (
                          <Panel
                            className={
                              item?.subRegions
                                ? 'panel-height parent-region'
                                : 'panel-height sub-region'
                            }
                            header={renderRegionHeader(
                              item,
                              regionCopy,
                              regionIndex,
                            )}
                            collapsible={
                              item?.zipCodes?.length < showZip && 'disabled'
                            }
                            showArrow={!(item?.zipCodes?.length < showZip)}
                            key={item?.id}
                          >
                            {map(item?.zipCodes?.slice(showZip), (zip) => (
                              <Tag
                                key={zip}
                                title={zip}
                                className={`single-item ${
                                  regionCopy?.[regionIndex]?.zipCodes?.includes(
                                    zip,
                                  )
                                    ? 'zip-highlight'
                                    : ''
                                }`}
                                onClick={(e) => handleClickZip(e, zip, item)}
                              >
                                <span className="content-text">
                                  {displayZipCodes(zip)}
                                </span>
                              </Tag>
                            ))}
                          </Panel>
                        );
                      })}
                    </Collapse> */}
                  </div>
                ) : (
                  <>
                    {!regionPricingData?.regionPricingModifier
                      ?.isValidZipCode ? (
                      <Empty
                        className="no-data-image"
                        image={Empty?.PRESENTED_IMAGE_SIMPLE}
                      />
                    ) : (
                      <div className="d-flex flex-vertical">
                        <span className="no-results">No results</span>
                        <Tag className="tag-plus" onClick={addSingleZipCode}>
                          <span className="content-text">
                            <PlusOutlined /> Add {regionSearchValue}
                          </span>
                        </Tag>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
      {selectedAttribute ===
        QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_MANUFACTURER && (
        <>
          {manufacturersLoading ? (
            <LoaderComponent setHeight={75} />
          ) : (
            <>
              {manufacturersData?.manufacturers?.data?.length > 0 ? (
                <div>
                  <Checkbox
                    className="common-checkbox check-all"
                    indeterminate={
                      !!(
                        conditionData?.manufacturers?.length > 0 &&
                        conditionData?.manufacturers?.length !==
                          manufacturersData?.manufacturers?.data?.length
                      )
                    }
                    checked={
                      conditionData?.manufacturers?.length ===
                      manufacturersData?.manufacturers?.data?.length
                    }
                    onChange={onCheckAllChangeManufacturers}
                  >
                    ALL MANUFACTURERS
                  </Checkbox>
                  <div className="center-content">
                    <Checkbox.Group
                      className="fill-width"
                      value={map(
                        conditionData?.manufacturers,
                        (item) => item?.id,
                      )}
                    >
                      <Row gutter={[0, 16]}>
                        {map(manufacturersData?.manufacturers?.data, (item) => (
                          <Col span={8} key={item?.id}>
                            <Checkbox
                              className="common-checkbox"
                              value={item?.id}
                              onChange={(e) =>
                                handleChangeCheckboxManufacturers(e, item)
                              }
                            >
                              <div>
                                <img
                                  src={item?.logo?.url || placeholderImage}
                                  alt="manufacturer-img"
                                  onError={(e) => {
                                    e.target.src = placeholderImage;
                                  }}
                                  className="list-img"
                                  width={24}
                                  height={24}
                                />
                                <span>{item?.name}</span>
                              </div>
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </div>
                </div>
              ) : (
                <Empty
                  className="no-data-image"
                  image={Empty?.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </>
          )}
        </>
      )}
      {selectedAttribute ===
        QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_UNIT_INPUT_PARAMETER && (
        <>
          <Checkbox
            onChange={handleVariableRateChange}
            checked={conditionData?.unitInputParameter?.variableRateCheck}
            className="common-checkbox mt-10"
          >
            Use for variable rate
          </Checkbox>
          <div className="d-flex mt-10 fill-width">
            <SelectComponent
              placeholder="Select Conversion Parameter"
              allowClear
              className="fill-width"
              value={conditionData?.unitInputParameter?.conversionParameterId}
              notFoundContent={
                conversionParameterLoading ? (
                  <LoaderComponent size="small" setHeight={10} />
                ) : (
                  <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                )
              }
              onBlur={handleConversionParameterBlur}
              onSearch={debouncedConversionParameterHandler}
              onClear={handleConversionParameterClear}
              onPopupScroll={onConversionParameterScroll}
              onSelect={handleConversionParameterSelect}
              filterOption={false}
            >
              {map(conversionParameterData, (item) => (
                <Option
                  key={item?.id}
                  value={item?.id}
                  data-name={item?.name}
                  data-key={item?.key}
                  data-units-of-measure-title={item?.unitsOfMeasure?.title}
                  data-units-of-measure-id={item?.unitsOfMeasure?.id}
                >
                  {!isEmpty(item?.unitsOfMeasure) ? (
                    <span key={item?.id}>
                      {item?.name} ({item?.unitsOfMeasure?.title})
                    </span>
                  ) : (
                    <span key={item?.id}>{item?.name}</span>
                  )}
                </Option>
              ))}
            </SelectComponent>
          </div>
        </>
      )}
      {hasAttribute && (
        <>
          <div className="mt-10 fill-width d-flex">
            <Radio.Group
              onChange={handleRadioGroupChange}
              className="common-radio fill-width d-flex"
              value={conditionData?.unitInputParameter?.condition}
            >
              <Row gutter={[5, 5]} className="fill-width">
                {map(QUOTE_MODIFIER_NUMERIC_CONDITIONS, (condition) => (
                  <Col span={8} key={condition?.value}>
                    <Radio value={condition?.value} key={condition?.value}>
                      {condition?.label}
                    </Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </div>
          <div>
            {conversionParamObj?.condition ===
              CONDITIONS_KEYS?.GREATER_LESS_RANGE && (
              <div className="d-flex mt-16 fill-width">
                <div className="width-percent-50">
                  <div className="d-flex align-center">
                    <span className="switch-logo">Greater than</span>

                    <Switch
                      checked={
                        conditionData?.unitInputParameter?.greaterThanCheck
                      }
                      onChange={(e) => onSwitchChanges(e, 'greaterThanCheck')}
                      className="common-switch ml-12"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </div>

                  <PriceComponent
                    prefix=""
                    decimalValue={2}
                    allowNegative={false}
                    placeholder="Enter Greater Than Value"
                    isPrice={false}
                    className={`mt-10 custom-number ${
                      !isEmpty(errorObject?.greaterThanValue)
                        ? 'number-error'
                        : ''
                    }`}
                    value={conditionData?.unitInputParameter?.greaterThanValue}
                    onChange={(e, sourceInfo) =>
                      handleNumberChange(
                        sourceInfo?.event?.target?.value,
                        'greaterThanValue',
                      )
                    }
                    disabled={
                      !conditionData?.unitInputParameter?.greaterThanCheck
                    }
                  />
                  {!isEmpty(errorObject?.greaterThanValue) && (
                    <span className="custom-error-message">
                      {errorObject?.greaterThanValue}
                    </span>
                  )}
                </div>
                <div className="ml-12 width-percent-50">
                  <div className="d-flex align-center">
                    <span className="switch-logo">Less than</span>

                    <Switch
                      checked={conditionData?.unitInputParameter?.lessThanCheck}
                      onChange={(e) => onSwitchChanges(e, 'lessThanCheck')}
                      className="common-switch ml-12"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </div>

                  <PriceComponent
                    prefix=""
                    decimalValue={2}
                    allowNegative={false}
                    placeholder="Enter Less Than Value"
                    isPrice={false}
                    className={`mt-10 custom-number ${
                      !isEmpty(errorObject?.lessThanValue) ? 'number-error' : ''
                    }`}
                    onChange={(e, sourceInfo) =>
                      handleNumberChange(
                        sourceInfo?.event?.target?.value,
                        'lessThanValue',
                      )
                    }
                    value={conditionData?.unitInputParameter?.lessThanValue}
                    disabled={!conditionData?.unitInputParameter?.lessThanCheck}
                  />
                  {!isEmpty(errorObject?.lessThanValue) && (
                    <span className="custom-error-message">
                      {errorObject?.lessThanValue}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            {conversionParamObj?.condition === CONDITIONS_KEYS?.EQUAL && (
              <>
                <PriceComponent
                  prefix=""
                  decimalValue={2}
                  allowNegative={false}
                  placeholder="Enter Equal To Value"
                  isPrice={false}
                  className={`mt-10 custom-number ${
                    !isEmpty(errorObject?.value) ? 'number-error' : ''
                  }`}
                  value={conditionData?.unitInputParameter?.value}
                  onChange={(e, sourceInfo) =>
                    handleNumberChange(
                      sourceInfo?.event?.target?.value,
                      'value',
                    )
                  }
                  decimalScale={2}
                  fixedDecimalScale
                />
                {!isEmpty(errorObject?.value) && (
                  <span className="custom-error-message">
                    {errorObject?.value}
                  </span>
                )}
              </>
            )}
            {conversionParamObj?.condition === CONDITIONS_KEYS?.NOT_EQUAL && (
              <>
                <PriceComponent
                  prefix=""
                  decimalValue={2}
                  allowNegative={false}
                  placeholder="Enter Not Equal To Value"
                  isPrice={false}
                  className={`mt-10 custom-number ${
                    !isEmpty(errorObject?.value) ? 'number-error' : ''
                  }`}
                  value={conditionData?.unitInputParameter?.value}
                  onChange={(e, sourceInfo) => {
                    // we are used sourceInfo value here to show trailing 0's after decimal point, refer ->DRF-8151
                    handleNumberChange(
                      sourceInfo?.event?.target?.value,
                      'value',
                    );
                  }}
                  decimalScale={2}
                  fixedDecimalScale
                />
                {!isEmpty(errorObject?.value) && (
                  <span className="custom-error-message">
                    {errorObject?.value}
                  </span>
                )}
              </>
            )}
          </div>
        </>
      )}
    </ModalComponent>
  );
};

export default PricingConditionModal;
