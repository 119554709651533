import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Space } from 'antd';
import { forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { fetchStep, formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import SelectComponent from '../../../components/SelectComponent';
import { CREATE_USER } from '../../users/graphql/Mutations';
import { ROLES } from '../graphql/Queries';
import StepProcess from '../pages/StepProcess';

const { required, email, requiredWhiteSpaceAllowed } = formValidatorRules;

const { Option } = SelectComponent;

const InviteTeam = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState([{ show: false }]);
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(true);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [initialValue, setInitialValue] = useState({
    users: [{ email: undefined }],
  });
  const { data } = useQuery(ROLES, {
    variables: {
      filter: {
        sortOn: 'label',
        sortBy: 'ASC',
        skip: 0,
        limit: 20,
        search: '',
        distinct: true,
        getDBField: ['label', 'key'],
      },
    },
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const { getOnboardingData, dispatch } = useContext(AppContext);

  const onBoardingData = getOnboardingData();

  const [createUser, { loading: createUserLoading }] = useMutation(
    CREATE_USER,
    {
      onCompleted: () => {
        fetchStep({ dispatch, setLoading: false, changeRoute: false });
        navigate(`${ROUTES?.ONBOARDING}/success`);
      },
      onError: () => {},
    },
  );

  useEffect(() => {
    if (onBoardingData?.data?.user?.length > 0) {
      const users = [];
      const showCopy = [];
      forEach(onBoardingData?.data?.user, (item) => {
        users?.push({
          email: item?.email,
          roles: item?.roles?.[0],
        });
        showCopy?.push({ show: true });
      });
      showCopy?.push({ show: false });
      users?.push({ email: undefined });
      setShow(showCopy);
      setInitialValue({ users });
      setLoading(false);
    } else {
      setInitialValue({
        users: [{ email: undefined }],
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onFinish = (values) => {
    const sentValues = [];
    forEach(values?.users, (item) => {
      if (item?.roles) {
        sentValues?.push({
          ...item,
          createdFrom: 'ONBOARDING',
          roles: [item?.roles],
          isAgain: onBoardingData?.data?.user?.some(
            ({ email: oldEmail }) => item?.email === oldEmail,
          ),
        });
      }
    });

    if (sentValues?.length > 0) {
      createUser({
        variables: {
          data: sentValues,
        },
      });
    } else {
      fetchStep({ dispatch, setLoading: false, changeRoute: false });
      navigate(`${ROUTES?.ONBOARDING}/success`);
    }
  };

  const onInputChange = (e, index) => {
    const showCopy = [...show];
    if (e?.target?.value?.length > 0) {
      showCopy[index].show = true;
    }
    if (e?.target?.value?.length < 1 && index + 1 >= show?.length) {
      showCopy[index].show = false;
    }
    setShow(showCopy);
  };

  return (
    <div className="steps">
      <StepProcess>
        {loading ? (
          <LoaderComponent spinning={loading} setHeight="calc(100vh - 110px)" />
        ) : (
          <div className="invite-team">
            <span className="steps-content-title">Invite Team Members</span>
            <span className="steps-content-description">
              Collabrate on content and build great digital products together
            </span>
            <div className="user-list">
              <span className="email-margin">Email</span>
              <span>Role</span>
            </div>
            <Form
              form={form}
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
              autoComplete="off"
              initialValues={initialValue}
              validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
            >
              <Form.List name="users">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        size="large"
                        key={key}
                        className="space-users"
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'email']}
                          fieldKey={[fieldKey, 'email']}
                          rules={[email, show?.[name]?.show && required]}
                        >
                          <InputComponent
                            className="email-width"
                            onChange={(e) => onInputChange(e, name)}
                            placeholder="Add email"
                          />
                        </Form.Item>
                        {show?.[name].show && (
                          <Form.Item
                            {...restField}
                            name={[name, 'roles']}
                            fieldKey={[fieldKey, 'roles']}
                            rules={[requiredWhiteSpaceAllowed]}
                          >
                            <SelectComponent
                              size="large"
                              placeholder="Select role"
                              onSelect={() => {
                                if (fields?.length === name + 1) {
                                  const showCopy = [...show];
                                  showCopy?.push({ show: false });
                                  setShow(showCopy);
                                  add();
                                }
                              }}
                            >
                              {map(data?.roles?.data, (role) => (
                                <Option key={role?.key} value={role?.key}>
                                  {role?.label}
                                </Option>
                              ))}
                            </SelectComponent>
                          </Form.Item>
                        )}
                        {fields?.length > 1 && show?.[name].show && (
                          <DeleteOutlined
                            className="delete-icon"
                            onClick={() => {
                              const showCopy = [...show];
                              showCopy?.splice(name, 1);
                              setShow(showCopy);
                              remove(name);
                            }}
                          />
                        )}
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="invite-submit submit-btn"
                  loading={createUserLoading}
                >
                  {form?.getFieldValue('users')?.[0]?.email ||
                  form?.getFieldValue('users')?.[0]?.roles
                    ? 'Send Invites'
                    : 'Skip'}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </StepProcess>
    </div>
  );
};

export default InviteTeam;
