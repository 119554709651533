import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row } from 'antd';
import React from 'react';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';

const { email } = formValidatorRules;

const EmailNotification = ({ form = null, isSponsor }) => (
  <Card className="card-wrapper">
    <div className="setting-content-heading">
      <h5>Notification Email</h5>
    </div>

    <Form.List name="emailNotification">
      {(fields, { add, remove }) => (
        <>
          {fields?.map(({ key, name, ...restField }) => {
            // WE NEED TO CHECK THIS CODE ONCE ASSOCIATION IS DONE
            const isAddedByTenant =
              form?.getFieldValue([
                'emailNotification',
                name,
                'addedByTenant',
              ]) || true;
            const isCreatedBySponsor = !isSponsor && !isAddedByTenant;

            return (
              <Row key={key} gutter={16}>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    label="Name"
                    rules={[{ required: true, message: 'Name is required' }]}
                  >
                    <InputComponent
                      disabled={isCreatedBySponsor}
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    {...restField}
                    name={[name, 'email']}
                    label="Email"
                    rules={[
                      { required: true, message: 'Email is required' },
                      email,
                    ]}
                    className="ml-4"
                  >
                    <InputComponent
                      disabled={isCreatedBySponsor}
                      placeholder="Enter Email"
                    />
                  </Form.Item>
                </Col>
                {!form?.getFieldValue(['emailNotification', name, 'default']) &&
                  !isCreatedBySponsor && (
                    <DeleteOutlined
                      className="delete-icon"
                      onClick={() => {
                        remove(name);
                      }}
                    />
                  )}
              </Row>
            );
          })}
          <Form.Item>
            <Button
              type="primary"
              onClick={() =>
                add({
                  name: '',
                  email: '',
                  default: false,
                  addedByTenant: !isSponsor,
                })
              }
              icon={<PlusOutlined />}
            >
              Add Email
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </Card>
);

export default EmailNotification;
