import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import logo from '../../assets/logo-login.svg';
import {
  RESEND_OTP_ACCOUNT_MINUTES,
  RESEND_OTP_TIME,
  ROUTES,
  USER_EMAIL,
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import InputComponent from '../../components/InputComponent';
import LoaderComponent from '../../components/LoaderComponent';
import { LOGIN } from './graphql/Mutations';

const { requiredWhiteSpaceAllowed, required, email } = formValidatorRules;

function getQuery() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search = '' } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URLSearchParams(search), [search]);
}

const getUserCred = () =>
  // eslint-disable-next-line no-undef
  localStorage?.getItem(USER_EMAIL) || null;

const Login = () => {
  const {
    initializeAuth,
    defaultPageSize,
    state: { isSponsor },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {
      navigate(ROUTES?.LOGIN, { replace: true });
      setLoading(false);
    },
  });

  const userEmail = getUserCred();

  useEffect(() => {
    if (userEmail) {
      form?.setFieldsValue({
        email: userEmail,
      });
    }
  }, [userEmail]);

  const onFinish = async (values) => {
    try {
      const response = await loginMutate({
        variables: {
          data: { ...values, portal: process.env.REACT_APP_PORTAL },
        },
      });
      if (response?.data?.webLogin) {
        // It bypasses the usual tenant login procedure to allow the admin to access the tenant's account
        if (values?.token) {
          const { authToken, user } = response?.data?.webLogin ?? {};
          initializeAuth(authToken, user);
          navigate(ROUTES?.MAIN);
        } else {
          // eslint-disable-next-line no-undef
          localStorage.setItem(
            RESEND_OTP_TIME,
            moment()?.add(RESEND_OTP_ACCOUNT_MINUTES, 'minutes')?.valueOf() ??
              null,
          );
          // eslint-disable-next-line no-undef
          localStorage.setItem(
            USER_EMAIL,
            values?.email?.trim().toLowerCase() ?? null,
          );

          navigate(ROUTES?.OTP_VERIFICATION);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const query = getQuery();
  useEffect(() => {
    if (query?.get('token')) {
      defaultPageSize();
      onFinish({ token: query?.get('token') });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return (
    <>
      <div className="gx-login-container">
        <LoaderComponent
          spinning={loginLoading}
          wrapperClassName="gx-login-content"
        >
          <div className="gx-login-header gx-text-center">
            <img src={logo} alt="path.pro-logo" className="mb-4" width={250} />
          </div>
          <Form
            name="Login"
            autoComplete="off"
            onFinish={onFinish}
            form={form}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item name="email" rules={[requiredWhiteSpaceAllowed, email]}>
              <InputComponent
                placeholder="Email"
                prefix={<UserOutlined />}
                aria-label="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[required]}
              normalize={(value) => value?.split(' ')?.join('')}
            >
              <InputComponent.Password
                className="password-input"
                prefix={<LockOutlined />}
                placeholder="Password"
                aria-label="Password"
              />
            </Form.Item>
            <Form.Item className="gx-text-center">
              <Button
                type="primary"
                className="common-button login-button fill-width font-size-14"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
            <Form.Item className="d-flex reset-password">
              <Link to={ROUTES?.RESET}>Forgot password ?</Link>
            </Form.Item>
            {!isSponsor && (
              <Form.Item className="d-flex reset-password">
                <Link to={ROUTES?.SIGNUP}>Register here</Link>
              </Form.Item>
            )}
          </Form>
        </LoaderComponent>
      </div>
    </>
  );
};

export default Login;
