import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PERMISSION_NOTIFICATION_LOGS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import Error404 from '../../Error404';
import './notificationLogsModule.less';
import NotificationLogList from './pages/NotificationLogList';
import NotificationLogView from './pages/NotificationLogView';

const NotificationLogsWrapper = () => (
  <div className="notification-log-wrapper ">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_NOTIFICATION_LOGS}
            showNoAccess
          >
            <NotificationLogList />
          </AccessControl>
        }
      />

      <Route
        path="/view/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_NOTIFICATION_LOGS_VIEW']}
            showNoAccess
          >
            <NotificationLogView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default NotificationLogsWrapper;
