import { ArrowRightOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { checkPermissions, getEntityFieldName } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { Columns } from '../entityColumns/Columns';
import { GET_BATCH_ENTITY_RECORDS } from '../graphql/Queries';

const EntityRecordTable = () => {
  const {
    state: { pageSize, permissions },
  } = useContext(AppContext);

  const initialEntityRecordFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'entityName',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const { 'batch-entity-id': batchEntityId, id } = useParams();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState(null);

  const [data, setData] = useState([]);
  const [dataCopyState, setDataCopyState] = useState([]);
  const [loading, setLoading] = useState(true);

  const [fetchBatchEntityRecord] = useLazyQuery(GET_BATCH_ENTITY_RECORDS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setData([...res?.batchEntityRecords]);
      setDataCopyState([...res?.batchEntityRecords]);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, [
      'FET_DATA_ASSIGNMENT_LIST',
    ]);

    if (permitted) {
      fetchBatchEntityRecord({
        variables: {
          filter: initialEntityRecordFilter,
          where: {
            id: batchEntityId,
            entityName: location?.state?.entityName,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onSearchChange = (search) => {
    if (search) {
      setLoading(true);
      const filteredRecords = dataCopyState?.filter((item) =>
        Object?.values(item)?.some(
          (value) =>
            value &&
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(search?.toLowerCase()?.trim()),
        ),
      );
      setData(filteredRecords?.length > 0 ? [...filteredRecords] : []);
      setLoading(false);
    } else {
      setData([...dataCopyState]);
      setLoading(false);
    }
  };

  return (
    <div>
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton
            customLink={`${ROUTES?.ASSIGNMENT_JOBS}/edit/${id}${ROUTES?.ENTITY_FILTERS}`}
          />
          <span className="portal-header">
            {location?.state?.batchName} <ArrowRightOutlined />{' '}
            {getEntityFieldName(location?.state?.entityName)}
          </span>
        </div>
      </Portal>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_BRAND_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Search name or other detail.."
              name="EntityRecords"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </AccessControl>
        </div>
      </div>
      <AccessControl
        allowedPermissions={['FET_DATA_ASSIGNMENT_LIST']}
        showNoAccess
      >
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={loading}
              columns={[
                ...Columns(location?.state?.entityName)?.filter(
                  (item) => item !== false,
                ),
              ]}
              data={data || []}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default EntityRecordTable;
