import { DownloadOutlined } from '@ant-design/icons';
import { TinyColor } from '@ctrl/tinycolor';
import { Button, Checkbox, Col, Divider, Form, Radio, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { messageContext } from '../../../app/components/AppContextHolder';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { ALLOW_IMAGE_TYPES, ROUTES } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import ColorInputComponent from '../../../components/ColorInputComponent';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import EditorComponent from '../../../components/EditorComponent';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';

const { required, requiredWhiteSpaceAllowed, color } = formValidatorRules;

const { Option } = SelectComponent;

const BrandForm = (props) => {
  const {
    state: { permissions, isSponsor },
    dispatch,
  } = useContext(AppContext);
  const { brandData, handleSubmit, isSubmit, isEdit = false } = props;
  const [validationTriggered, setValidationTriggered] = useState(false);
  const navigate = useNavigate();

  const [fileList, setFileList] = useState([]);
  const [secondaryColor, setSecondaryColor] = useState('');
  const [tertiaryColor, setTertiaryColor] = useState('');
  const [colorPalletValue, setColorPalletValue] = useState('');
  const [disabled, setDisable] = useState(false);
  const [disableColorPalletSection, setDisableColorPalletSection] = useState(
    true,
  );

  const [selectedColor, setSelectedColor] = useState('');
  const { state } = useLocation();

  const [form] = Form?.useForm();
  const colorPalleteOptions = [
    {
      key: 'PRIMARY',
      value: 'Just Primary Color',
    },
    {
      key: 'CUSTOM',
      value: 'Custom',
    },
    {
      key: 'CONSERVATIVE',
      value: 'Conservative',
    },
    {
      key: 'MIDDLE_GROUND',
      value: 'Middle Ground',
    },
    {
      key: 'COLORFUL',
      value: 'Colorful',
    },
  ];
  useEffect(() => {
    if (brandData?.logo && !fileList?.length) {
      setFileList([brandData?.logo]);
    }
    if (isEdit && brandData?.primaryColor) {
      setDisableColorPalletSection(false);
    }

    if (brandData?.colorPallete === 'PRIMARY') {
      setDisable(true);
    }

    if (brandData?.secondaryColors) {
      setSecondaryColor(brandData?.secondaryColors);
    }
    if (brandData?.tertiaryColors) {
      setTertiaryColor(brandData?.tertiaryColors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedColor || brandData?.primaryColor) {
      if (colorPalletValue === 'CUSTOM') {
        setDisable(false);
        setSecondaryColor('');
        setTertiaryColor('');
        form?.setFieldsValue({ tertiaryColors: '' });
        form?.setFieldsValue({
          secondaryColors: '',
        });
      }
      if (colorPalletValue === 'PRIMARY') {
        setDisable(true);
        form?.setFieldsValue({ tertiaryColors: '' });
        form?.setFieldsValue({
          secondaryColors: '',
        });
        setSecondaryColor('');
        setTertiaryColor('');
      }
      if (colorPalletValue === 'CONSERVATIVE') {
        setDisable(false);
        const monoChromatic = new TinyColor(
          selectedColor || brandData?.primaryColor,
        )
          ?.monochromatic(2)
          ?.map((t) => t?.toHexString());
        setSecondaryColor(monoChromatic?.[1]);
        setTertiaryColor('');
        form?.setFieldsValue({
          secondaryColors: monoChromatic?.[1],
        });
        form?.setFieldsValue({ tertiaryColors: '' });
      }

      if (colorPalletValue === 'MIDDLE_GROUND') {
        setDisable(false);
        const analogous = new TinyColor(
          selectedColor || brandData?.primaryColor,
        )
          ?.analogous(3)
          ?.map((t) => t?.toHexString());
        setSecondaryColor(analogous?.[1]);
        setTertiaryColor(analogous?.[2]);
        form?.setFieldsValue({
          secondaryColors: analogous?.[1],
        });
        form?.setFieldsValue({ tertiaryColors: analogous?.[2] });
      }
      if (colorPalletValue === 'COLORFUL') {
        const triadic = new TinyColor(selectedColor || brandData?.primaryColor)
          ?.triad()
          ?.map((t) => t?.toHexString());
        setSecondaryColor(triadic?.[1]);
        setTertiaryColor(triadic?.[2]);
        form?.setFieldsValue({
          secondaryColors: triadic?.[1],
        });
        form?.setFieldsValue({ tertiaryColors: triadic?.[2] });
      }
    } else {
      setSelectedColor('');
      setSecondaryColor('');
      setTertiaryColor('');
      form?.setFieldsValue({
        secondaryColors: '',
      });
      form?.setFieldsValue({ tertiaryColors: '' });
      form?.setFieldsValue({ colorPallete: null });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColor, colorPalletValue, brandData?.primaryColor]);

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url },
    } = info;
    if (url) {
      setFileList([]);
      form?.setFieldsValue({
        logo: null,
      });
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      if (info?.file?.status === 'removed') {
        form?.setFieldsValue({
          logo: null,
        });
      } else {
        form?.setFieldsValue({
          logo: info?.file,
        });
      }
      setFileList([...info?.fileList]);
    } else {
      setFileList([]);
      form?.setFieldsValue({
        logo: null,
      });
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const handlePickColor = (pickerColor) => {
    setSelectedColor(pickerColor);
    setDisableColorPalletSection(false);
  };

  const handleColorPalateChange = (value) => {
    setColorPalletValue(value);
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleColorPaletteClear = () => {
    setSecondaryColor('');
    setTertiaryColor('');
    form?.setFieldsValue({
      secondaryColors: null,
    });
    form?.setFieldsValue({ tertiaryColors: null });
  };

  return (
    <div className="content-section">
      <style>
        {`.ant-checkbox-checked .ant-checkbox-inner { background-color:${
          selectedColor || brandData?.primaryColor
        };border-color:${selectedColor || brandData?.primaryColor}; }
        .common-radio .ant-radio-checked .ant-radio-inner { border: 6px solid ${
          selectedColor || brandData?.primaryColor
        }; }
      }\
          `}
      </style>
      <AccessControl
        allowedPermissions={
          isEdit ? ['FET_BRAND_UPDATE'] : ['FET_BRAND_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="brand-table-discard-btn"
            onClick={() => navigate(ROUTES?.RETAILERS, { state: { ...state } })}
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            disabled={!isSponsor && brandData?.sponsorName?.length > 0}
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="brand-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={brandData}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        disabled={brandData?.sponsorName && !isSponsor}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit ? ['FET_BRAND_UPDATE'] : ['FET_BRAND_CREATE'],
            )
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider " />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="logo"
                label="Logo"
                rules={[
                  { required, message: 'Please Upload Logo', type: 'object' },
                ]}
              >
                <DraggerUploadComponent
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                    showRemoveIcon: checkPermissions(permissions, [
                      'FET_BRAND_UPDATE',
                    ]),
                  }}
                  fileList={fileList}
                  onChange={onChangeUpload}
                >
                  <p className="icon-header">
                    <DownloadOutlined />
                  </p>
                  <p className="upload-title">
                    Drag the retailer's logo here or browse your files
                  </p>
                  <p className="upload-description">
                    200x200 px minimum | JPG, PNG, SVG
                  </p>
                </DraggerUploadComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Name' }]}
                name="name"
                label="Name"
              >
                <InputComponent allowClear placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status',
                  },
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="primaryColor"
                label="Primary Color"
                rules={[
                  { ...required, message: 'Please Select Primary Color' },
                  color,
                ]}
              >
                <ColorInputComponent
                  disabled={brandData?.sponsorName && !isSponsor}
                  placeholder="Select Primary Colour"
                  onChange={handlePickColor}
                  externalStateUpdate={colorPalletValue}
                  color={selectedColor || brandData?.primaryColor}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="preview-label">Example Area</div>
          <Row gutter={16} className="required-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <div className="preview-div">
                <div className="d-flex justify-between">
                  <div>
                    <div
                      className="preview-blocks"
                      style={{
                        backgroundColor:
                          selectedColor || brandData?.primaryColor,
                        color: '#FFFFFF',
                      }}
                    >
                      <span>Primary Button</span>
                    </div>
                    <div
                      className="preview-blocks"
                      style={{
                        backgroundColor: '#F1F3F7',
                        color: selectedColor || brandData?.primaryColor,
                      }}
                    >
                      <span>Secondary Button</span>
                    </div>
                  </div>
                  <div
                    className="preview-link"
                    style={{ color: selectedColor || brandData?.primaryColor }}
                  >
                    <span>Tertiary Action</span>
                  </div>
                  <div>
                    <div className="brand-text-left">
                      <Checkbox className="mr-8" disabled />
                      <Checkbox checked>Checkbox</Checkbox>
                    </div>
                    <div className="brand-text-left">
                      <Radio disabled />
                      <Radio className="common-radio" checked>
                        Radio Button
                      </Radio>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <span className="form-divider-text">OPTIONAL</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            {brandData?.sponsorName && !isSponsor && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="sponsorName" label="Sponsor">
                  <InputComponent disabled />
                </Form.Item>
              </Col>
            )}
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="colorPallete" label="Color Palette">
                <SelectComponent
                  allowClear
                  onChange={handleColorPalateChange}
                  placeholder="Select Color Palette"
                  onClear={handleColorPaletteClear}
                  disabled={
                    disableColorPalletSection ||
                    (brandData?.sponsorName && !isSponsor)
                  }
                >
                  {colorPalleteOptions?.map((option) => (
                    <Option key={option?.key} value={option?.key}>
                      {option?.value}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="secondaryColors"
                label="Secondary Color"
                rules={[color]}
              >
                <ColorInputComponent
                  placeholder="Select Secondary Color"
                  color={secondaryColor}
                  externalStateUpdate={colorPalletValue}
                  disabled={
                    disabled ||
                    disableColorPalletSection ||
                    (brandData?.sponsorName && !isSponsor)
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="tertiaryColors"
                label="Tertiary Color"
                rules={[color]}
              >
                <ColorInputComponent
                  placeholder="Select Tertiary Color"
                  externalStateUpdate={colorPalletValue}
                  color={tertiaryColor}
                  disabled={
                    disabled ||
                    disableColorPalletSection ||
                    (brandData?.sponsorName && !isSponsor)
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" label="Description">
                <EditorComponent
                  disabled={brandData?.sponsorName && !isSponsor}
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="waiverText" label="Waiver Text">
                <EditorComponent
                  disabled={brandData?.sponsorName && !isSponsor}
                  placeholder="Enter Waiver Text"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="offerText" label="Offer Text">
                <EditorComponent
                  disabled={brandData?.sponsorName && !isSponsor}
                  placeholder="Enter Offer Text"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="priceGuarantee" label="Price Guarantee">
                <EditorComponent
                  disabled={brandData?.sponsorName && !isSponsor}
                  placeholder="Enter Price Guarantee"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="productDetails" label="Product Details">
                <EditorComponent
                  disabled={brandData?.sponsorName && !isSponsor}
                  placeholder="Enter Product Details"
                />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default BrandForm;
