import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Row,
  Tag,
  message,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import ImportIcon from '../../../assets/import.svg';
import { ROUTES, SKIP_RECORD, exportOptions } from '../../../common/constants';
import {
  checkPermissions,
  formatPrice,
  handleExportCommon,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import ImportModal from '../../imports/components/ImportModal';
import { DELETE_PRODUCT_ITEM, UPDATE_PRODUCT_ITEM } from '../graphql/Mutations';
import {
  GET_PRODUCT_ITEMS,
  GET_SPONSOR_FILTER,
  INDUSTRY_FILTER,
  LINE_OF_BUSINESS_FILTER,
  MANUFACTURER_FILTER,
  PRODUCT_CATEGORY_FILTER,
  PRODUCT_ITEM_FILTER,
  SUB_AREA_FILTER,
  UNIT_INPUT_PARAMETER_FILTER,
  UNIT_OF_MEASURE_FILTER,
} from '../graphql/Queries';

let scrollDebounce = null;

const initialPaginationValue = {
  total: 0,
  current: 1,
};
const ProductItemTable = () => {
  const {
    state: { pageSize, permissions, isSponsor },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const initialProductItemFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'createdAt',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [productItemFilter, setProductItemFilter] = useState(
    initialProductItemFilter,
  );
  const [filters, setFilters] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [
    deleteProductItem,
    { loading: deleteProductItemLoading },
  ] = useMutation(DELETE_PRODUCT_ITEM, {
    onError() {},
  });

  const [
    updateProductItemMutate,
    { loading: updateProductItemLoading },
  ] = useMutation(UPDATE_PRODUCT_ITEM, {
    onError() {},
  });

  const [fetchProductItemData, { loading, data }] = useLazyQuery(
    GET_PRODUCT_ITEMS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.productItems?.count,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  const [productItemFilters] = useLazyQuery(PRODUCT_ITEM_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.productItemFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.productItemFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.productItemFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [unitOfMeasureFilters] = useLazyQuery(UNIT_OF_MEASURE_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.unitOfMeasureFilters?.data, (item) =>
          optionsCopy?.push(item?.title),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.unitOfMeasureFilters?.data, (item) =>
          optionsCopy?.push(item?.title),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.unitOfMeasureFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [unitOfMeasureInputFilters] = useLazyQuery(
    UNIT_INPUT_PARAMETER_FILTER,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (scrollFlag) {
          const optionsCopy = [...filterList];
          forEach(res?.unitInputParameterFilters?.data, (item) =>
            optionsCopy?.push(item?.name),
          );
          setFilterList(optionsCopy);
          setScrollFlag(false);
        } else {
          const optionsCopy = [];
          forEach(res?.unitInputParameterFilters?.data, (item) =>
            optionsCopy?.push(item?.name),
          );
          setFilterList(optionsCopy);
        }
        setFilterIsEnd(
          res?.unitInputParameterFilters?.data?.length < SKIP_RECORD,
        );
        setFilterLoading(false);
      },
      onError: () => {
        setFilterLoading(false);
      },
    },
  );

  const [industryFilters] = useLazyQuery(INDUSTRY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.industryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [lineOfBusinessFilters] = useLazyQuery(LINE_OF_BUSINESS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.lineOfBusinessFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [subAreaFilters] = useLazyQuery(SUB_AREA_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.subAreaFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [manufacturerFilters] = useLazyQuery(MANUFACTURER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.manufacturerFilters?.data, (item) =>
          optionsCopy?.push(item?.name),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.manufacturerFilters?.data, (item) =>
          optionsCopy?.push(item?.name),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.manufacturerFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [productCategoryFilters] = useLazyQuery(PRODUCT_CATEGORY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.productCategoryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.productCategoryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.productCategoryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [sponsorFilter] = useLazyQuery(GET_SPONSOR_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.sponsorFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_PRODUCT_ITEM_LIST']);
    const whereFilter = location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    if (permitted) {
      fetchProductItemData({
        variables: {
          filter: parsedObject?.productItemFilter ?? productItemFilter,
          ...(parsedObject && { where: parsedObject?.filters }),
        },
      });
      if (!isEmpty(parsedObject)) {
        setFilters({ ...parsedObject?.filters });
        setFiltersCopyState({
          ...parsedObject?.filters,
        });
        setSearchValue(parsedObject?.productItemFilter?.search);
        const sorter = {
          order:
            parsedObject?.productItemFilter?.sortBy === 'ASC'
              ? 'ascend'
              : 'descend',
          columnKey: parsedObject?.productItemFilter?.sortOn,
        };
        setSortedInfo({ ...sorter });
        setProductItemFilter({ ...parsedObject?.productItemFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('beforeunload', () => {
      // eslint-disable-next-line no-undef
      if (window?.location?.pathname === ROUTES?.PRODUCTS)
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
    });
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('beforeunload', () => {
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
      });
    };
  }, []);

  const getFilterData = (confirm, dataIndex) => {
    if (dataIndex === 'costPrice' || dataIndex === 'defaultPrice') {
      if (
        !filtersCopyState?.[dataIndex]?.min?.toString() ||
        !filtersCopyState?.[dataIndex]?.max?.toString()
      ) {
        message?.destroy();
        message?.error('Please enter values');
        return;
      }
      if (filtersCopyState?.[dataIndex]?.min < 0) {
        return message?.error('Min value can not be less than zero');
      }
      if (filtersCopyState?.[dataIndex]?.max < 0) {
        return message?.error('Max value can not be less than zero');
      }
      if (
        filtersCopyState?.[dataIndex]?.min >= filtersCopyState?.[dataIndex]?.max
      ) {
        return message?.error(
          'Min value can not be greater than or equal to max value',
        );
      }
    }
    fetchProductItemData({
      variables: {
        filter: { ...productItemFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    setProductItemFilter({
      ...productItemFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'industry':
          industryFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                getDBField: 'label',
                justShow: true,
              },
            },
          });
          break;

        case 'lineOfBusiness':
          lineOfBusinessFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                distinct: true,
                getDBField: 'label',
              },
            },
          });
          break;

        case 'subArea':
          subAreaFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                distinct: true,
                getDBField: 'label',
              },
            },
          });
          break;

        case 'manufacturer':
          manufacturerFilters({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'name',
              },
            },
          });
          break;

        case 'productCategories':
          productCategoryFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
              },
            },
          });
          break;

        case 'unitForMeasure':
          unitOfMeasureFilters({
            variables: {
              filter: {
                skip: 0,
                limit: 20,
                search: filterSearch,
                getDBField: 'title',
              },
            },
          });
          break;

        case 'unitInputParameter':
          unitOfMeasureInputFilters({
            variables: {
              filter: {
                skip: 0,
                limit: 20,
                search: filterSearch,
                getDBField: 'name',
              },
            },
          });
          break;

        case 'sponsorName':
          sponsorFilter({
            variables: {
              filter: {
                sortOn: 'businessName',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'businessName',
              },
            },
          });
          break;

        case 'defaultPrice':
        case 'costPrice':
          // no api call for range filter;
          break;

        default:
          productItemFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'industry':
            industryFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  getDBField: 'label',
                  justShow: true,
                },
              },
            });
            break;

          case 'lineOfBusiness':
            lineOfBusinessFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  distinct: true,
                  getDBField: 'label',
                },
              },
            });
            break;

          case 'subArea':
            subAreaFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  justShow: true,
                  getDBField: 'label',
                },
              },
            });
            break;

          case 'manufacturer':
            manufacturerFilters({
              variables: {
                filter: {
                  sortOn: 'name',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'name',
                },
              },
            });
            break;

          case 'productCategories':
            productCategoryFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                },
              },
            });
            break;

          case 'unitForMeasure':
            unitOfMeasureFilters({
              variables: {
                filter: {
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  getDBField: 'title',
                },
              },
            });
            break;

          case 'unitInputParameter':
            unitOfMeasureInputFilters({
              variables: {
                filter: {
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  getDBField: 'name',
                },
              },
            });
            break;

          case 'sponsorName':
            sponsorFilter({
              variables: {
                filter: {
                  sortOn: 'businessName',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'businessName',
                },
              },
            });
            break;

          default:
            productItemFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };
    if (dataIndex === 'costPrice' || dataIndex === 'defaultPrice') {
      delete filtersCopy?.[dataIndex];
    }
    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);
    fetchProductItemData({
      variables: {
        filter: {
          ...productItemFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setProductItemFilter({
      ...productItemFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditProductItem = (record) => {
    const otherFilters = { productItemFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);

    if (record?.id) {
      navigate(`${ROUTES?.PRODUCTS}/edit/${record?.id}`, {
        state: {
          stringifyObject,
        },
      });
    } else {
      navigate(`${ROUTES?.PRODUCTS}/add`, {
        state: {
          stringifyObject,
        },
      });
    }
  };

  const handleViewProductItem = (record) => {
    const otherFilters = { productItemFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);
    navigate(`${ROUTES?.PRODUCTS}/view/${record?.id}`, {
      state: {
        stringifyObject,
      },
    });
  };

  const handleDeleteProductItem = async (editProductItemData) => {
    const response = await deleteProductItem({
      variables: { where: { id: editProductItemData?.id } },
    });
    if (response?.data?.deleteProductItem) {
      fetchProductItemData({
        variables: {
          filter: productItemFilter,
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setProductItemFilter(initialProductItemFilter);
    setSortedInfo({});
    fetchProductItemData({
      variables: { filter: initialProductItemFilter },
    });
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setProductItemFilter({
        ...productItemFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchProductItemData({
        variables: {
          filter: {
            ...productItemFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setProductItemFilter({
        ...productItemFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchProductItemData({
        variables: {
          filter: {
            ...productItemFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'industry':
        industryFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              getDBField: 'label',
              justShow: true,
            },
          },
        });
        break;

      case 'lineOfBusiness':
        lineOfBusinessFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              distinct: true,
              getDBField: 'label',
            },
          },
        });
        break;

      case 'subArea':
        subAreaFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              justShow: true,
              getDBField: 'label',
            },
          },
        });
        break;

      case 'manufacturer':
        manufacturerFilters({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'name',
            },
          },
        });
        break;

      case 'productCategories':
        productCategoryFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
            },
          },
        });
        break;

      case 'unitForMeasure':
        unitOfMeasureFilters({
          variables: {
            filter: {
              skip: 0,
              limit: 20,
              search: value,
              getDBField: 'title',
            },
          },
        });
        break;

      case 'unitInputParameter':
        unitOfMeasureInputFilters({
          variables: {
            filter: {
              skip: 0,
              limit: 20,
              search: value,
              getDBField: 'name',
            },
          },
        });
        break;

      case 'sponsorName':
        sponsorFilter({
          variables: {
            filter: {
              sortOn: 'businessName',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'businessName',
            },
          },
        });
        break;

      default:
        productItemFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const handleChangeAmount = (value, input, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: { ...filtersCopyState?.[dataIndex], [input]: value },
    };

    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex, isPrice = false) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (isPrice) {
        return (
          <div className="custom-filter-dropdown amount-filter-dropdown">
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.[dataIndex]
                      ? filtersCopyState?.[dataIndex]?.min
                      : null
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'min', dataIndex)
                  }
                  placeholder="min"
                />
              </Col>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.[dataIndex]
                      ? filtersCopyState?.[dataIndex]?.max
                      : null
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'max', dataIndex)
                  }
                  placeholder="max"
                />
              </Col>
            </Row>
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                htmlType="submit"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'isActive' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span
                        title={
                          item?.label || isPrice
                            ? formatPrice(item)
                            : item?.toString()
                        }
                      >
                        {item?.label || isPrice
                          ? formatPrice(item)
                          : item?.toString()}
                      </span>
                    )}
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item,
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span
                          title={
                            isPrice
                              ? formatPrice(item)
                              : item?.label || item?.toString()
                          }
                        >
                          {isPrice
                            ? formatPrice(item)
                            : item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const handleProductItemStatus = async (editProductItemData) => {
    const editData = {
      name: editProductItemData?.name,
      sku: editProductItemData?.sku,
      uuid: editProductItemData?.uuid,
      defaultPrice: editProductItemData?.defaultPrice,
      costPrice: editProductItemData?.costPrice,
      lineOfBusinessId: editProductItemData?.lineOfBusiness?.id,
      subAreaId: editProductItemData?.subArea?.id,
      manufacturerId: editProductItemData?.manufacturer?.id,
      isActive: !editProductItemData?.isActive,
      stock: editProductItemData?.stock,
      quoteDescription: editProductItemData?.quoteDescription,
      industryId: editProductItemData?.industryId,
      workOrderActivity: !!editProductItemData?.workOrderActivity,
    };

    delete editData?.sponsorName;

    const response = await updateProductItemMutate({
      variables: {
        data: { ...editData },
        where: { id: editProductItemData?.id },
      },
    });
    if (response?.data?.updateProductItem) {
      fetchProductItemData({
        variables: {
          filter: productItemFilter,
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const renderActionButtons = (editProductItemData) => (
    <div className="d-flex flex-vertical">
      <AccessControl
        allowedPermissions={[
          'FET_PRODUCT_ITEM_UPDATE',
          'FET_PRODUCT_ITEM_VIEW',
        ]}
      >
        <Button
          id="productItem-table-edit-btn"
          className="b-0"
          onClick={() => handleAddEditProductItem(editProductItemData)}
        >
          Edit
        </Button>
      </AccessControl>
      <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_VIEW']}>
        <Button
          id="productItem-table-edit-btn"
          className="b-0"
          onClick={() => handleViewProductItem(editProductItemData)}
        >
          View
        </Button>
      </AccessControl>
      {((editProductItemData?.sponsorName && isSponsor) ||
        (!editProductItemData?.sponsorName && !isSponsor)) && (
        <>
          <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_DELETE']}>
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDeleteProductItem(editProductItemData)}
              okText="Yes"
              cancelText="No"
            >
              <Button id="productItem-table-status-btn" className="b-0">
                Remove
              </Button>
            </Popconfirm>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_UPDATE']}>
            <Popconfirm
              title={`Are you sure to ${
                editProductItemData?.isActive ? 'Mark Inactive' : 'Mark Active'
              }?`}
              onConfirm={() => handleProductItemStatus(editProductItemData)}
              okText="Yes"
              cancelText="No"
            >
              <Button id="productItem-table-status-btn" className="b-0">
                {editProductItemData?.isActive
                  ? 'Mark Inactive'
                  : 'Mark Active'}
              </Button>
            </Popconfirm>
          </AccessControl>
        </>
      )}
    </div>
  );

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'sku' && sortedInfo?.order,
      width: 120,
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
    },
    {
      title: 'MSRP',
      dataIndex: 'msrp',
      key: 'msrp',
      width: 140,
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'msrp' && sortedInfo?.order,
      render: (msrp) => <span>{msrp || '-'}</span>,
    },
    !isSponsor && {
      title: 'SPONSOR',
      dataIndex: 'sponsorName',
      key: 'sponsorName',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'sponsorName' && sortedInfo?.order,
      width: 140,
      ...filterPopup('sponsorName'),
      render: (sponsorName) => <span>{sponsorName || '-'}</span>,
    },
    {
      title: 'MANUFACTURER',
      dataIndex: 'manufacturerId',
      key: 'manufacturerId',
      sorter: true,
      ellipsis: true,
      sortOrder:
        sortedInfo?.columnKey === 'manufacturerId' && sortedInfo?.order,
      width: 180,
      ...filterPopup('manufacturer'),
      render: (manufacturer, record) => (
        <span title={record?.manufacturer?.name}>
          {record?.manufacturer?.name}
        </span>
      ),
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industryId',
      key: 'industryId',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'industryId' && sortedInfo?.order,
      width: 190,
      ...filterPopup('industry'),
      render: (industryId, record = {}) => (
        <span>{record?.industry?.label}</span>
      ),
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusinessId',
      key: 'lineOfBusinessId',
      sorter: true,
      ellipsis: true,
      sortOrder:
        sortedInfo?.columnKey === 'lineOfBusinessId' && sortedInfo?.order,
      width: 190,
      ...filterPopup('lineOfBusiness'),
      render: (lineOfBusinessId, record) => (
        <span title={record?.lineOfBusiness?.label}>
          {record?.lineOfBusiness?.label}
        </span>
      ),
    },
    {
      title: 'SERVICE TYPE',
      dataIndex: 'subAreaId',
      key: 'subAreaId',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'subAreaId' && sortedInfo?.order,
      width: 160,
      ...filterPopup('subArea'),
      render: (subAreaId, record) => (
        <span title={record?.subArea?.label}>{record?.subArea?.label}</span>
      ),
    },
    {
      title: 'UNIT OF MEASURE',
      dataIndex: 'unitForMeasureObj',
      key: 'unitForMeasureObj',
      ellipsis: true,
      ...filterPopup('unitForMeasure'),
      width: 140,
      render: (unitForMeasureObj = null) => (
        <span title={unitForMeasureObj?.title || '-'}>
          {unitForMeasureObj?.title || '-'}
        </span>
      ),
    },
    {
      title: 'CONVERSION PARAMETER',
      dataIndex: 'unitInputParameterObj',
      key: 'unitInputParameterObj',
      ...filterPopup('unitInputParameter'),
      ellipsis: true,
      width: 140,
      render: (unitInputParameterObj = null) => (
        <span title={unitInputParameterObj?.name || '-'}>
          {unitInputParameterObj?.name || '-'}
        </span>
      ),
    },
    {
      title: 'COST',
      dataIndex: 'costPrice',
      key: 'costPrice',
      sorter: true,
      ellipsis: true,
      align: 'right',
      sortOrder: sortedInfo?.columnKey === 'costPrice' && sortedInfo?.order,
      width: 130,
      className: 'max-width-column',
      ...filterPopup('costPrice', true),
      render: (costPrice) => (
        <span title={formatPrice(costPrice)}>{formatPrice(costPrice)}</span>
      ),
    },
    {
      title: 'DEF.PRICE',
      dataIndex: 'defaultPrice',
      key: 'defaultPrice',
      sorter: true,
      ellipsis: true,
      align: 'right',
      sortOrder: sortedInfo?.columnKey === 'defaultPrice' && sortedInfo?.order,
      width: 140,
      className: 'max-width-column',
      ...filterPopup('defaultPrice', true),
      render: (defaultPrice) => (
        <span title={formatPrice(defaultPrice)}>
          {formatPrice(defaultPrice)}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      },
    },
    checkPermissions(permissions, [
      'FET_PRODUCT_ITEM_UPDATE',
      'FET_PRODUCT_ITEM_DELETE',
      'FET_PRODUCT_ITEM_VIEW',
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => (
        <Popover
          placement="bottom"
          overlayClassName="action-button"
          content={renderActionButtons(record)}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  const onSearchChange = (value) => {
    setProductItemFilter({
      ...productItemFilter,
      skip: value
        ? 0
        : productItemFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    fetchProductItemData({
      variables: {
        filter: {
          ...productItemFilter,
          skip: value
            ? 0
            : productItemFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'PRODUCT_ITEM',
    });
    setExportLoading(false);
  };

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="products"
        folder="PRODUCT_ITEM"
      />
      <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="productItem-table-add-btn"
            type="primary"
            onClick={handleAddEditProductItem}
          >
            Add Product
          </Button>
        </Portal>
      </AccessControl>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Product name or other detail.."
              name="ProductItems"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_IMPORT']}>
            <Button
              size="small"
              className="common-button import-button"
              icon={<img src={ImportIcon} alt="import-icon" width={11} />}
              id="productItem-table-import-btn"
              type="primary"
              onClick={() => handleShowImportModal(true)}
            >
              Import
            </Button>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              menu={{ items: exportOptions, onClick: handleExport }}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="productItem-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl
        allowedPermissions={['FET_PRODUCT_ITEM_LIST']}
        showNoAccess
      >
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={
                loading || updateProductItemLoading || deleteProductItemLoading
              }
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.productItems?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default ProductItemTable;
