import { MenuOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Empty,
  Popconfirm,
  Popover,
  Tag,
} from 'antd';
import {
  debounce,
  filter,
  forEach,
  isArray,
  isEmpty,
  map,
  pickBy,
  set,
  startCase,
} from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ShowMoreIconComponent from '../../../app/components/iconComponents/ShowMoreIconComponent';
import FilterIcon from '../../../assets/filter.svg';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  checkPermissions,
  createUnitInputParameterMessage,
  formatUnits,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import {
  CHANGE_PRIORITY,
  DELETE_PRICING_MODIFIER,
  UPDATE_PRICING_MODIFIER,
} from '../graphql/Mutations';
import {
  GET_PRICING_MODIFIERS,
  GET_SPONSOR_FILTER,
  PRICING_MODIFIER_FILTER,
} from '../graphql/Queries';

let scrollDebounce = null;
let modifierStatus;

function DraggableWrapper(props) {
  const { children, ...restProps } = props;
  /**
   * 'children[1]` is `dataSource`
   * Check if `children[1]` is an array
   * because antd gives 'No Data' element when `dataSource` is an empty array
   */
  return (
    <SortableContext
      key={children?.[0]?.key}
      items={
        children?.[1] instanceof Array
          ? children?.[1]?.map((child) => child?.id)
          : []
      }
      strategy={verticalListSortingStrategy}
      {...restProps}
    >
      <tbody {...restProps}>
        {
          // This invokes `Table.components.body.row` for each element of `children`.
          children
        }
      </tbody>
    </SortableContext>
  );
}

function DraggableRow(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    overIndex,
    index,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props?.['data-row-key'],
    // eslint-disable-next-line react/destructuring-assignment
    key: props?.['data-row-key'],
  });
  const isOver = overIndex === index;
  const { children, ...restProps } = props;
  const isData = children instanceof Array;
  const style = {
    ...restProps?.style,
    ...(isData && isDragging ? { background: '#80808038' } : {}),
    ...(isData && isOver ? { borderTop: '5px solid #ec161638' } : {}),
  };
  /**
   * 'children[1]` is a row of `dataSource`
   * Check if `children[1]` is an array
   * because antd gives 'No Data' element when `dataSource` is an empty array
   */
  return (
    <tr ref={setNodeRef} {...attributes} {...restProps} style={style}>
      {children instanceof Array
        ? children?.map((child) => {
            const { key, ...rest } = child;
            return key === 'dragHandle' ? (
              <td {...listeners} {...rest}>
                {child}
              </td>
            ) : (
              <td {...rest}>{child}</td>
            );
          })
        : children}
    </tr>
  );
}

const PricingModifierTable = () => {
  const {
    state: { permissions, isSponsor },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const initialPricingModifierFilter = {
    sortOn: 'order',
    sortBy: 'ASC',
  };

  const PRICING_STATUS = {
    PM_ACTIVE: 'Active',
    PM_INACTIVE: 'Inactive',
    PM_SCHEDULE: 'Scheduled',
    PM_EXPIRED: 'Expired',
  };

  const [sortedInfo, setSortedInfo] = useState({});
  const [pricingModifierFilter, setPricingModifierFilter] = useState(
    initialPricingModifierFilter,
  );
  const [filters, setFilters] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [pricingData, setPricingData] = useState([]);
  const [dragLoading, setDragLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  // ID to render overlay.
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active?.id);
  };

  const [
    updatePricingModifierMutate,
    { loading: updatePricingModifierLoading },
  ] = useMutation(UPDATE_PRICING_MODIFIER, {
    onError() {},
  });

  const [
    deletePricingModifier,
    { loading: deletePricingModifierLoading },
  ] = useMutation(DELETE_PRICING_MODIFIER, {
    onError() {},
  });

  const [fetchPricingModifierData, { loading }] = useLazyQuery(
    GET_PRICING_MODIFIERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setPricingData([...res?.pricingModifiers?.data]);
      },
      onError() {},
    },
  );

  const [pricingModifierFilters] = useLazyQuery(PRICING_MODIFIER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.pricingModifierFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.pricingModifierFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.pricingModifierFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [sponsorFilter] = useLazyQuery(GET_SPONSOR_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.sponsorFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });
  const [changePriority] = useMutation(CHANGE_PRIORITY, {
    onCompleted() {},
    onError() {},
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, [
      'FET_PRICING_MODIFIER_LIST',
    ]);
    const whereFilter = location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    if (permitted) {
      fetchPricingModifierData({
        variables: {
          filter:
            parsedObject?.pricingModifierFilterClone ?? pricingModifierFilter,
          ...(parsedObject && { where: parsedObject?.filters }),
        },
      });

      if (!isEmpty(parsedObject)) {
        setFilters({ ...parsedObject?.filters });
        setFiltersCopyState({
          ...parsedObject?.filters,
        });

        setSearchValue(parsedObject?.pricingModifierFilterClone?.search);
        const sorter = {
          ...(!isEmpty(parsedObject?.pricingModifierFilterClone) && {
            order:
              parsedObject?.pricingModifierFilterClone?.sortBy === 'ASC'
                ? 'ascend'
                : 'descend',
            columnKey: parsedObject?.pricingModifierFilterClone?.sortOn,
          }),
        };
        setSortedInfo({ ...sorter });
        setPricingModifierFilter({
          ...parsedObject?.pricingModifierFilterClone,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('beforeunload', () => {
      // eslint-disable-next-line no-undef
      if (window?.location?.pathname === ROUTES?.PRICING_MODIFIERS)
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
    });
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('beforeunload', () => {
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
      });
    };
  }, []);

  const handleDragEnd = async (event) => {
    setDragLoading(true);
    const { active, over } = event;

    if (active?.id !== over?.id) {
      const oldIndex = pricingData?.findIndex(
        (item) => item?.id === active?.id,
      );
      const newIndex = pricingData?.findIndex((item) => item?.id === over?.id);
      try {
        // Call your API to update the order
        const res = await changePriority({
          variables: {
            data: {
              oldIndex: pricingData?.[oldIndex]?.order,
              newIndex: pricingData?.[newIndex]?.order,
            },
          },
        });
        if (res?.data?.changePriority?.data?.id) {
          setPricingData((items) => arrayMove(items, oldIndex, newIndex));
          setDragLoading(false);
        }
      } catch (error) {
        setDragLoading(false);
        return error;
      }
    }
  };

  const getFilterData = (confirm) => {
    fetchPricingModifierData({
      variables: {
        filter: pricingModifierFilter,
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'sponsorName':
          sponsorFilter({
            variables: {
              filter: {
                sortOn: 'businessName',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'businessName',
              },
            },
          });
          break;

        default:
          pricingModifierFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'sponsorName':
            sponsorFilter({
              variables: {
                filter: {
                  sortOn: 'businessName',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'businessName',
                },
              },
            });
            break;

          default:
            pricingModifierFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };
    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);
    fetchPricingModifierData({
      variables: {
        filter: {
          ...pricingModifierFilter,
          sortOn: 'order',
          sortBy: 'ASC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditPricingModifier = (record) => {
    const pricingModifierFilterClone = {
      ...pricingModifierFilter,
      ...(pricingModifierFilter?.sortBy && {
        sortBy: pricingModifierFilter?.sortBy,
      }),
    };
    const otherFilters = {
      pricingModifierFilterClone,
      filters,
    };

    const stringifyObject = JSON?.stringify(otherFilters);

    if (record?.id) {
      navigate(`${ROUTES?.PRICING_MODIFIERS}/edit/${record?.id}`, {
        state: { stringifyObject },
      });
    } else {
      navigate(`${ROUTES?.PRICING_MODIFIERS}/add`, {
        state: { stringifyObject },
      });
    }
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    setSortedInfo(sorter);
    if (sorter?.column) {
      setPricingModifierFilter({
        ...pricingModifierFilter,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchPricingModifierData({
        variables: {
          filter: {
            ...pricingModifierFilter,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setPricingModifierFilter({
        ...pricingModifierFilter,
        sortOn: 'order',
        sortBy: 'ASC',
      });
      fetchPricingModifierData({
        variables: {
          filter: {
            ...pricingModifierFilter,
            sortOn: 'order',
            sortBy: 'ASC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'sponsorName':
        sponsorFilter({
          variables: {
            filter: {
              sortOn: 'businessName',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'businessName',
            },
          },
        });
        break;

      default:
        pricingModifierFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'modifierOn' && dataIndex !== 'status' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => (
                <Tag
                  key={item?.toString()}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  {dataIndex === 'status' ? (
                    <span title={PRICING_STATUS[item]}>
                      {PRICING_STATUS[item]}
                    </span>
                  ) : (
                    <span>{item?.toString()}</span>
                  )}
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => (
                <div
                  className="filter-checkbox-section"
                  key={item?.key || item}
                >
                  <Checkbox
                    value={item?.key || item}
                    checked={filtersCopyState?.[dataIndex]?.includes(
                      item?.key || item,
                    )}
                    key={item?.key || item}
                    onChange={(e) => changeFilter(e, dataIndex)}
                    className="common-checkbox"
                  >
                    {dataIndex === 'status' ? (
                      <span title={PRICING_STATUS[item]}>
                        <span title={PRICING_STATUS[item]}>
                          {PRICING_STATUS[item]}
                        </span>
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Checkbox>
                </div>
              ))
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const handleDeletePricingModifier = async (editPricingModifierData) => {
    const response = await deletePricingModifier({
      variables: { where: { id: editPricingModifierData?.id } },
    });
    if (response?.data?.deletePricingModifier) {
      setPricingModifierFilter({
        ...pricingModifierFilter,
        skip: 0,
        limit: pricingData?.length,
      });
      fetchPricingModifierData({
        variables: {
          filter: {
            ...pricingModifierFilter,
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handlePricingModifierStatus = async (editPricingModifierData) => {
    switch (editPricingModifierData?.status) {
      case 'PM_ACTIVE':
        modifierStatus = 'PM_INACTIVE';
        break;
      case 'PM_INACTIVE':
        modifierStatus = 'PM_ACTIVE';
        break;
      case 'PM_SCHEDULE':
        modifierStatus = 'PM_ACTIVE';
        break;

      case 'PM_EXPIRED':
        modifierStatus = 'PM_ACTIVE';
        break;

      default:
        break;
    }

    const conditions = {};
    if (editPricingModifierData?.brands?.length > 0) {
      set(
        conditions,
        'brands',
        editPricingModifierData?.brands?.map((item) => item?.id),
      );
    }
    if (editPricingModifierData?.conditions?.regions?.length > 0) {
      set(
        conditions,
        'regions',
        editPricingModifierData?.conditions?.regions?.map((item) => ({
          id: item?.id || null,
          all: item?.all || false,
          zipCodes: item?.all ? [] : item?.zipCodes,
        })),
      );
    }

    if (editPricingModifierData?.manufacturers?.length > 0) {
      set(
        conditions,
        'manufacturers',
        editPricingModifierData?.manufacturers?.map((item) => item?.id),
      );
    }
    if (!isEmpty(editPricingModifierData?.conditions?.unitInputParameter)) {
      set(
        conditions,
        'unitInputParameter',
        editPricingModifierData?.conditions?.unitInputParameter,
      );
    }

    const editData = {
      status: modifierStatus,
      conditions,
      modifierOn: editPricingModifierData?.modifierOn,
      pricingOperation: editPricingModifierData?.pricingOperation,
      pricingValue: editPricingModifierData?.pricingValue,
      pricingValueBest: editPricingModifierData?.pricingValueBest,
      pricingValueBetter: editPricingModifierData?.pricingValueBetter,
      addItemAs: editPricingModifierData?.addItemAs,
      pricingValueType: editPricingModifierData?.pricingValueType,
      billingAs: editPricingModifierData?.billingAs,
      lineItemName: editPricingModifierData?.lineItemName,
      industry: editPricingModifierData?.industry,
      lineOfBusiness: editPricingModifierData?.lineOfBusiness,
      subArea: [...editPricingModifierData?.subArea],
    };

    const response = await updatePricingModifierMutate({
      variables: {
        data: { ...editData },
        where: { id: editPricingModifierData?.id },
      },
    });
    if (response?.data?.updatePricingModifier) {
      setPricingModifierFilter({
        ...pricingModifierFilter,
      });
      fetchPricingModifierData({
        variables: {
          filter: {
            ...pricingModifierFilter,
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const renderActionButtons = (editPricingModifierData) => (
    <div className="d-flex flex-vertical">
      <AccessControl
        allowedPermissions={[
          'FET_PRICING_MODIFIER_UPDATE',
          'FET_PRICING_MODIFIER_VIEW',
        ]}
      >
        <Button
          id="pricingModifier-table-edit-btn"
          className="b-0"
          onClick={() => handleAddEditPricingModifier(editPricingModifierData)}
        >
          Edit
        </Button>
      </AccessControl>
      {((editPricingModifierData?.sponsorName && isSponsor) ||
        (!editPricingModifierData?.sponsorName && !isSponsor)) && (
        <>
          <AccessControl allowedPermissions={['FET_PRICING_MODIFIER_DELETE']}>
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() =>
                handleDeletePricingModifier(editPricingModifierData)
              }
              okText="Yes"
              cancelText="No"
            >
              <Button id="pricingModifier-table-status-btn" className="b-0">
                Remove
              </Button>
            </Popconfirm>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_PRICING_MODIFIER_UPDATE']}>
            <Popconfirm
              title={`Are you sure to ${
                editPricingModifierData?.status === 'PM_ACTIVE'
                  ? 'Mark Inactive'
                  : 'Mark Active'
              }?`}
              onConfirm={() =>
                handlePricingModifierStatus(editPricingModifierData)
              }
              okText="Yes"
              cancelText="No"
            >
              <Button id="pricingModifier-table-status-btn" className="b-0">
                {editPricingModifierData?.status === 'PM_ACTIVE'
                  ? 'Mark Inactive'
                  : 'Mark Active'}
              </Button>
            </Popconfirm>
          </AccessControl>
        </>
      )}
    </div>
  );

  const conditionRenderer = (record) => {
    let showMoreButton = false;
    const conditionObj = {
      brands: record?.brands || [],
      manufacturers: record?.manufacturers || [],
      regions: record?.regions || [],
      unitInputParameter: record?.conditions?.unitInputParameter || {},
    };

    const finalConditionObj = pickBy(conditionObj, (value) => !isEmpty(value));

    const total = Object?.keys(finalConditionObj)?.length;
    const firstCondition =
      finalConditionObj[Object?.keys(finalConditionObj)?.[0]] || [];

    const content = (
      <div key={record?.id} className="pricing-condition">
        {map(finalConditionObj, (value, key) => {
          if (key === 'unitInputParameter') {
            return (
              <div className="condition">
                <span className="condition-title">
                  {key === 'brands' ? 'Retailers' : startCase(key)} :
                </span>
                <span className="condition-data">
                  {createUnitInputParameterMessage(value)}
                </span>
              </div>
            );
          }
          return (
            <div className="condition" key={`${key}${record?.id}`}>
              <span className="condition-title">
                {key === 'brands' ? 'Retailers' : startCase(key)} :
              </span>
              {map(value, (val, index) => (
                <div key={`${index}${record?.id}${val?.id}`}>
                  {key === 'industry' ? (
                    <>
                      <span
                        className="condition-data"
                        key={`${index}${record?.id}${val?.id}`}
                      >
                        {val?.name || val?.label}
                        {' - '}
                        {map(val?.lineOfBusinesses, (lob, lobIndex) => (
                          <span key={`${index}${record?.id}${lobIndex}`}>
                            {lob?.label}
                            {' - '}
                            {map(lob?.subAreas, (subArea, subAreaIndex) => (
                              <span
                                key={`${index}${record?.id}${subAreaIndex}`}
                              >
                                {subArea?.label}
                              </span>
                            ))?.reduce((prev, curr) => {
                              if (prev === '') {
                                return curr;
                              }
                              return [prev, ', ', curr];
                            }, '')}
                          </span>
                        ))?.reduce((prev, curr) => {
                          if (prev === '') {
                            return curr;
                          }
                          return [prev, ', ', curr];
                        }, '')}
                      </span>
                    </>
                  ) : (
                    <>
                      {key === 'regions' ? (
                        <>
                          {val?.all ? (
                            <>
                              &nbsp;
                              <Tag className="region-tag">
                                {val?.name || '-'}
                              </Tag>
                            </>
                          ) : (
                            <>
                              &nbsp;{val?.name} {'=>'}
                              {map(val?.zipCodes, (zipCode) => (
                                <span key={zipCode}>{zipCode}</span>
                              ))?.reduce((prev, curr) => {
                                if (prev === '') {
                                  return curr;
                                }
                                return [prev, ', ', curr];
                              }, '')}
                            </>
                          )}
                        </>
                      ) : (
                        <span className="condition-data" key={val?.id}>
                          {val?.name || val?.label}
                        </span>
                      )}
                    </>
                  )}
                </div>
              ))?.reduce((prev, curr) => {
                if (prev === '') {
                  return curr;
                }
                return [prev, ', ', curr];
              }, '')}
              <br />
            </div>
          );
        })}
      </div>
    );

    if (
      total > 1 ||
      record?.brands?.length > 2 ||
      record?.manufacturers?.length > 2 ||
      Object?.keys(finalConditionObj)?.[0] === 'regions'
    ) {
      showMoreButton = true;
    } else {
      showMoreButton = false;
    }

    return (
      <div key={record?.id} className="pricing-condition">
        <span key={record?.id} className="condition-title">
          {Object?.keys(finalConditionObj)?.length &&
          Object?.keys(finalConditionObj)?.[0] === 'brands'
            ? 'Retailers'
            : startCase(Object?.keys(finalConditionObj)?.[0])}{' '}
          :
        </span>

        {isArray(firstCondition) ? (
          <>
            {map(firstCondition?.slice(0, 2), (firstObj, index) => (
              <span
                key={`${index}${firstObj?.id}`}
                className="condition-max-width"
              >
                {Object?.keys(finalConditionObj)?.length > 0 &&
                Object?.keys(finalConditionObj)?.[0] === 'regions' ? (
                  <Tag className="condition-region" key={firstObj?.id}>
                    {firstObj?.name}
                  </Tag>
                ) : (
                  <span className="condition-data" key={firstObj?.id}>
                    {firstObj?.name || firstObj?.label}
                  </span>
                )}
              </span>
            ))?.reduce((prev, curr) => {
              if (prev === '') {
                return curr;
              }
              return [prev, ', ', curr];
            }, '')}
          </>
        ) : (
          <div className="condition">
            <span className="condition-data">
              {createUnitInputParameterMessage(firstCondition)}
            </span>
          </div>
        )}

        {showMoreButton && (
          <Popover
            overlayClassName="pricing-popover"
            placement="rightBottom"
            content={content}
          >
            <Tag className="single-item pointer more-shadow ml-5">
              <span className="content-text">
                {total - 1 > 0 ? `${total - 1} more` : 'show more'}
                <ShowMoreIconComponent className="show-more-icon" />
              </span>
            </Tag>
          </Popover>
        )}
      </div>
    );
  };

  const showDrag = () => {
    let searchLength;
    let filterLength;
    let sorterLength;

    if (!pricingModifierFilter?.search?.length) {
      searchLength = false;
    } else {
      searchLength = true;
    }
    if (!filters) {
      filterLength = false;
    } else if (filters) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filters) {
        if (!filters[key]?.length) {
          filterLength = false;
        } else {
          filterLength = true;
        }
      }
    } else {
      filterLength = true;
    }

    if (!Object?.keys(sortedInfo)?.length || !sortedInfo?.order) {
      sorterLength = false;
    } else {
      sorterLength = true;
    }

    if (
      !filterLength &&
      !searchLength &&
      !sorterLength &&
      checkPermissions(permissions, ['FET_PRICING_MODIFIER_UPDATE'])
    ) {
      return true;
    }

    return false;
  };

  const columns = [
    showDrag() && {
      key: 'dragHandle',
      dataIndex: 'dragHandle',
      fixed: 'left',
      columnWidth: 50,
      width: 10,
      className: 'drag-visible',
      render: () => <MenuOutlined className="drag-row" />,
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'title' && sortedInfo?.order,
      width: 200,
      className: 'max-width-column',
    },
    !isSponsor && {
      title: 'SPONSOR',
      dataIndex: 'sponsorName',
      key: 'sponsorName',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'sponsorName' && sortedInfo?.order,
      width: 140,
      ...filterPopup('sponsorName'),
      render: (sponsorName) => <span>{sponsorName || '-'}</span>,
    },
    {
      title: 'CONDITIONS',
      dataIndex: 'conditions',
      key: 'conditions',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'conditions' && sortedInfo?.order,
      render: (conditions, record) => <>{conditionRenderer(record)}</>,
    },
    {
      title: 'MODIFIER ON',
      dataIndex: 'modifierOn',
      key: 'modifierOn',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'modifierOn' && sortedInfo?.order,
      width: 160,
      ...filterPopup('modifierOn'),
      render: (modifierOn) => {
        if (!modifierOn) {
          return <span>-</span>;
        }
        return <span>{`${modifierOn} Value `}</span>;
      },
    },
    {
      title: 'GOOD',
      dataIndex: 'pricingValue',
      key: 'pricingValue',
      sorter: true,
      ellipsis: true,
      onHeaderCell: () => ({
        style: {
          textAlign: 'right',
        },
      }),
      align: 'right',
      sortOrder: sortedInfo?.columnKey === 'pricingValue' && sortedInfo?.order,
      width: 130,
      render: (amount, record) => {
        if (record?.pricingValueType === '$') {
          return (
            <span>
              {record?.pricingOperation === 'ADD' ? '' : '-'}
              {record?.pricingValueType}
              {formatUnits(amount)}
            </span>
          );
        }
        return (
          <span>
            {record?.pricingOperation === 'ADD' ? '' : '-'}
            {formatUnits(amount)}
            {record?.pricingValueType}
          </span>
        );
      },
    },
    {
      title: 'BETTER',
      dataIndex: 'pricingValueBetter',
      key: 'pricingValueBetter',
      sorter: true,
      ellipsis: true,
      onHeaderCell: () => ({
        style: {
          textAlign: 'right',
        },
      }),
      align: 'right',
      sortOrder:
        sortedInfo?.columnKey === 'pricingValueBetter' && sortedInfo?.order,
      width: 130,
      render: (amount, record) => {
        if (record?.pricingValueType === '$') {
          return (
            <span>
              {record?.pricingOperation === 'ADD' ? '' : '-'}
              {record?.pricingValueType}
              {formatUnits(amount)}
            </span>
          );
        }
        return (
          <span>
            {record?.pricingOperation === 'ADD' ? '' : '-'}
            {formatUnits(amount)}
            {record?.pricingValueType}
          </span>
        );
      },
    },
    {
      title: 'BEST',
      dataIndex: 'pricingValueBest',
      key: 'pricingValueBest',
      sorter: true,
      ellipsis: true,
      onHeaderCell: () => ({
        style: {
          textAlign: 'right',
        },
      }),
      align: 'right',
      sortOrder:
        sortedInfo?.columnKey === 'pricingValueBest' && sortedInfo?.order,
      width: 130,
      render: (amount, record) => {
        if (record?.pricingValueType === '$') {
          return (
            <span>
              {record?.pricingOperation === 'ADD' ? '' : '-'}
              {record?.pricingValueType}
              {formatUnits(record?.pricingValueBest)}
            </span>
          );
        }
        return (
          <span>
            {record?.pricingOperation === 'ADD' ? '' : '-'}
            {formatUnits(amount)}
            {record?.pricingValueType}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      width: 120,
      ...filterPopup('status'),
      render: (status) => PRICING_STATUS[status],
    },
    checkPermissions(permissions, [
      'FET_PRICING_MODIFIER_UPDATE',
      'FET_PRICING_MODIFIER_DELETE',
      'FET_PRICING_MODIFIER_VIEW',
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => (
        <Popover
          placement="bottom"
          overlayClassName="action-button"
          content={renderActionButtons(record)}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  const onSearchChange = (value) => {
    setPricingModifierFilter({
      ...pricingModifierFilter,
      search: value,
    });
    fetchPricingModifierData({
      variables: {
        filter: {
          ...pricingModifierFilter,
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  return (
    <div className="position-relative">
      <AccessControl allowedPermissions={['FET_PRICING_MODIFIER_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="pricingModifier-table-add-btn"
            type="primary"
            onClick={handleAddEditPricingModifier}
          >
            Add Quote Modifier
          </Button>
        </Portal>
      </AccessControl>
      <div className="d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_PRICING_MODIFIER_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Pricing or other detail.."
              name="PricingModifiers"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </AccessControl>
        </div>
      </div>
      <AccessControl
        allowedPermissions={['FET_PRICING_MODIFIER_LIST']}
        showNoAccess
      >
        <span className="float-label">
          <Alert
            description="The Quote Modifiers are applied in order from top to bottom."
            type="info"
            showIcon
          />
        </span>
        <div className="common-table pricing-modifier-table">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <TableComponent
              loadingData={
                loading ||
                updatePricingModifierLoading ||
                deletePricingModifierLoading ||
                dragLoading
              }
              columns={[...columns?.filter((item) => item !== false)]}
              data={pricingData || []}
              onChange={handleTableChange}
              setHeight={180}
              rowKey="id"
              components={
                pricingData?.length > 0
                  ? {
                      body: {
                        wrapper: DraggableWrapper,
                        row: DraggableRow,
                      },
                    }
                  : {}
              }
            />
            {/* Render overlay component. */}
            <DragOverlay>
              <TableComponent
                fullHeight={false}
                columns={columns}
                showHeader={false}
                data={
                  activeId
                    ? [
                        pricingData?.[
                          pricingData?.findIndex(
                            (item) => item?.id === activeId,
                          )
                        ],
                      ]
                    : []
                }
              />
            </DragOverlay>
          </DndContext>
        </div>
      </AccessControl>
    </div>
  );
};

export default PricingModifierTable;
