import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import NotificationLogDetails from '../component/NotificationLogDetail';
import { GET_NOTIFICATION_LOG } from '../graphql/Queries';

const NotificationLogView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [getNotificationLog, { loading, data }] = useLazyQuery(
    GET_NOTIFICATION_LOG,
    {
      onCompleted: () => {},
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    getNotificationLog({
      variables: {
        where: {
          id,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  if (!id) {
    return <Navigate to={ROUTES?.NOTIFICATION_LOGS} replace />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={`${ROUTES?.NOTIFICATION_LOGS}`} />
          <span className="portal-header">
            {data && data?.notificationLog?.subject}
          </span>
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <NotificationLogDetails notificationData={data?.notificationLog} />
      )}
    </Card>
  );
};
export default NotificationLogView;
